<template>
    <NoButtonModal :open="open" id="the_new_client_dropdown_modal">
        <div class="modal-list-third-party-app">
            <div
                v-if="!showLinkForm"
                class="section-header d-flex justify-content-between align-items-center ps-4 pe-2 py-1"
            >
                <div v-if="isEditModule" class="d-flex justify-content-between align-items-center">
                    <span class="label-app-list-header">Edit Module</span>
                </div>

                <div v-else class="d-flex justify-content-between align-items-center">
                    <div v-if="!showAppListing" @click="showPage('FUNCTION_LISTING')">
                        <button class="text-button no-bg pointer">
                            <span class="material-icons-outlined">arrow_back</span>
                        </button>
                    </div>
                    <span v-if="showSelectSvgModal" class="label-app-list-header">Select SVG</span>
                    <span v-if="!showSelectSvgModal" class="label-app-list-header">Add Milestone</span>
                </div>

                <div
                    v-if="!showSelectSvgModal && !isEditMilestone"
                    class="d-flex justify-content-between align-items-center"
                >
                    <button
                        v-if="isEditModule && !showLoader && showMainForm && !showSelectSvgModal"
                        type="button"
                        class="no-bg text-button btn-add-new-app"
                        style="margin-right: 2rem"
                        @click="showPage('ADD_MILESTONE')"
                    >
                        Add Milestone
                    </button>
                </div>
                <div v-if="showSelectSvgModal" class="d-flex justify-content-between align-items-center">
                    <span class="d-flex search-svg-icon mx-4 my-2">
                        <input
                            type="text"
                            placeholder="Find icon here"
                            v-model="searchedSvg"
                            @input="handleSearchedSVG"
                        />
                        <img
                            src="@/assets/px/drag-and-drop/search-icon.svg"
                            alt=""
                            class="img mx-2"
                            style="width: 20px"
                        />
                    </span>
                </div>
            </div>
            <div class="modal-list-third-party-app" v-if="showLinkForm">
                <div class="section-header d-flex justify-content-between align-items-center ps-4 pe-2 py-1">
                    <div class="d-flex justify-content-between align-items-center">
                        <div @click="showPage('FUNCTION_LISTING')">
                            <button class="text-button no-bg pointer">
                                <span class="material-icons-outlined">arrow_back</span>
                            </button>
                        </div>
                        <span class="label-app-list-header">Add sub-milestone</span>
                    </div>
                </div>
            </div>
            <template v-if="!showSelectSvgModal && !showLoader && !isEditMilestone && !showLinkForm">
                <div class="section-body content-checkbox-list">
                    <template v-if="milestonesList.length > 0">
                        <!-- <draggable :list="milestonesList"> -->
                        <div
                            v-for="milestone in milestonesList"
                            :key="milestone.id"
                            class="d-flex justify-content-between align-items-center"
                        >
                            <!-- <ejs-tooltip
                                    v-if="milestonesList.length > 1"
                                    class="tooltipcontainer"
                                    content="Reorder"
                                    target="#target"
                                    cssClass="customtooltip"
                                    position="BottomRight"
                                >
                                    <img id="target" src="@/assets/px/drag-and-drop/drag_and_drop_icon.svg" alt="" />
                                </ejs-tooltip> -->
                            <span
                                class="w-70 label-app-list-header"
                                style="
                                    text-overflow: ellipsis;
                                    text-wrap: nowrap;
                                    overflow: hidden;
                                    text-transform: capitalize;
                                "
                                >{{ milestone.description }}</span
                            >
                            <div class="d-flex">
                                <ejs-tooltip
                                    class="tooltipcontainer"
                                    content="Add sub-milestone"
                                    target="#target"
                                    cssClass="customtooltip"
                                    position="BottomRight"
                                >
                                    <span
                                        id="target"
                                        class="material-symbols-outlined icon-edit me-2"
                                        role="button"
                                        @click="addSubMilestone(milestone.id)"
                                    >
                                        add
                                    </span>
                                </ejs-tooltip>
                                <ejs-tooltip
                                    class="tooltipcontainer"
                                    content="Edit"
                                    target="#target"
                                    cssClass="customtooltip"
                                    position="BottomRight"
                                >
                                    <span
                                        id="target"
                                        class="material-symbols-outlined icon-edit me-2"
                                        role="button"
                                        @click="editMilestone(milestone)"
                                    >
                                        edit_square
                                    </span>
                                </ejs-tooltip>
                                <ejs-tooltip
                                    class="tooltipcontainer"
                                    content="Delete"
                                    target="#target"
                                    cssClass="customtooltip"
                                    position="BottomRight"
                                >
                                    <span
                                        id="target"
                                        class="material-symbols-outlined icon-delete"
                                        role="button"
                                        @click="deleteMilestone(milestone.id)"
                                    >
                                        delete
                                    </span>
                                </ejs-tooltip>
                            </div>
                        </div>
                        <!-- </draggable> -->
                    </template>
                </div>
            </template>
            <template v-if="!showLoader && !showMainForm && showLinkForm">
                <div class="section-body content-checkbox-list">
                    <template v-if="subMilestonesList.length > 0">
                        <draggable :list="subMilestonesList" @change="handleSubMilestoneReorder">
                            <div
                                v-for="subMilestone in subMilestonesList"
                                :key="subMilestone.id"
                                class="d-flex justify-content-between align-items-center"
                            >
                                <ejs-tooltip
                                    v-if="subMilestonesList.length > 1"
                                    class="tooltipcontainer"
                                    content="Reorder"
                                    target="#target"
                                    cssClass="customtooltip"
                                    position="BottomRight"
                                >
                                    <img id="target" src="@/assets/px/drag-and-drop/drag_and_drop_icon.svg" alt="" />
                                </ejs-tooltip>
                                <span
                                    class="w-75 label-app-list-header"
                                    style="
                                        text-overflow: ellipsis;
                                        text-wrap: nowrap;
                                        overflow: hidden;
                                        text-transform: capitalize;
                                    "
                                    >{{ subMilestone.description }}</span
                                >
                                <div class="d-flex">
                                    <ejs-tooltip
                                        class="tooltipcontainer"
                                        content="Edit"
                                        target="#target"
                                        cssClass="customtooltip"
                                        position="BottomRight"
                                    >
                                        <span
                                            id="target"
                                            class="material-symbols-outlined icon-edit me-2"
                                            role="button"
                                            @click="editSubMilestone(subMilestone)"
                                        >
                                            edit_square
                                        </span>
                                    </ejs-tooltip>
                                    <ejs-tooltip
                                        class="tooltipcontainer"
                                        content="Delete"
                                        target="#target"
                                        cssClass="customtooltip"
                                        position="BottomRight"
                                    >
                                        <span
                                            id="target"
                                            class="material-symbols-outlined icon-delete"
                                            role="button"
                                            @click="deleteSubMilestone(subMilestone.id)"
                                        >
                                            delete
                                        </span>
                                    </ejs-tooltip>
                                </div>
                            </div>
                        </draggable>
                    </template>
                </div>
            </template>
            <hr
                v-if="
                    !showSelectSvgModal &&
                    !showLoader &&
                    ((!isEditMilestone && !showLinkForm && milestonesList.length) ||
                        (!isEditMilestone && !showMainForm && subMilestonesList.length))
                "
            />
        </div>

        <div class="modal-list-third-party-app">
            <ValidationObserver ref="formMilestone">
                <form style="box-shadow: none" class="p-0">
                    <template v-if="!showLoader && !showSelectSvgModal && !showMainForm && !showLinkForm">
                        <div class="section-body add-new-app-form mx-0">
                            <div class="mx-5 px-3">
                                <Input
                                    label="Title"
                                    labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                    type="text"
                                    class="field-container d-flex align-items-center mb-4"
                                    name="title"
                                    placeholder="Enter Title here"
                                    v-model="milestone.description"
                                    required
                                />
                                <span class="d-flex align-items-center mb-4" style="color: grey; padding-left: 10%">
                                    <input
                                        type="checkbox"
                                        id="addAttachmets"
                                        class="d-flex justify-content-end add-new-app-label right-5 color"
                                        v-model="addMilestoneAttachment"
                                        @input="resetMilestoneAttachments"
                                    />
                                    <label class="margin-0 font-14 d-flex align-items-center" for="addAttachmets"
                                        >Add attachment</label
                                    >
                                </span>
                                <template v-if="addMilestoneAttachment">
                                    <Input
                                        label="Title"
                                        labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                        type="text"
                                        class="field-container d-flex align-items-center mt-2 mb-4"
                                        name="Title"
                                        placeholder="Enter Title"
                                        v-model="milestoneAttachment.title"
                                        required
                                    />
                                    <span class="field-container d-flex align-items-center mt-2 mb-4">
                                        <label
                                            id="attachementType"
                                            class="me-3 d-flex add-new-app-label"
                                            style="width: 28%; justify-content: end"
                                            >Type*</label
                                        >
                                        <Multiselect
                                            id="attachementType"
                                            track-by="type"
                                            label="name"
                                            class="position-relative w-70"
                                            placeholder="Select Type"
                                            v-model="attachmentsType"
                                            :options="attachmentsTypes"
                                            :searchable="true"
                                            :allow-empty="false"
                                            :show-labels="false"
                                            @input="milestoneAttachment.type = $event.type"
                                        />
                                    </span>
                                    <Input
                                        label="Link"
                                        labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                        type="text"
                                        class="field-container d-flex align-items-center mt-2 mb-4"
                                        name="Link"
                                        placeholder="Enter Link"
                                        v-model="milestoneAttachment.link"
                                        required
                                    />
                                </template>
                            </div>
                        </div>
                        <div class="section-footer add-new-app-form my-4">
                            <div class="d-flex justify-content-center">
                                <button
                                    v-if="!isEditMilestone"
                                    type="button"
                                    class="primary btn-save-application"
                                    :disabled="showImgLoader"
                                    @click="addMilestone"
                                >
                                    Save
                                </button>
                                <button
                                    v-if="isEditMilestone"
                                    type="button"
                                    class="primary btn-save-application"
                                    :disabled="showImgLoader"
                                    @click="updateMilestone(currentMilestoneId)"
                                >
                                    Save
                                </button>
                                <button
                                    type="button"
                                    class="no-bg text-button btn-cancel px-4"
                                    @click="showPage('FUNCTION_LISTING')"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </template>
                </form>
            </ValidationObserver>
        </div>

        <div class="modal-list-third-party-app">
            <ValidationObserver ref="form">
                <form style="box-shadow: none" class="p-0">
                    <template v-if="!showLoader && showMainForm && !showSelectSvgModal">
                        <div class="section-body add-new-app-form mx-0">
                            <div class="app-img-upload block field-container d-flex align-items-center mb-2 mx-5 px-3">
                                <label class="me-3 d-flex justify-content-end add-new-app-label">Icon/Images*</label>

                                <div class="d-flex align-items-center w-100 gap-1">
                                    <button
                                        type="button"
                                        :class="
                                            (['kiosk', 'mobex-health-home'].includes(applicationFrame)
                                                ? 'w-40'
                                                : 'w-100') + ' no-bg text-button btn-add-new-app'
                                        "
                                        @click="chooseFile"
                                    >
                                        Choose file
                                    </button>
                                    <button
                                        v-if="['kiosk', 'mobex-health-home'].includes(applicationFrame)"
                                        type="button"
                                        class="no-bg text-button btn-add-new-app w-40"
                                        @click="chooseFromCompany"
                                    >
                                        Choose icon
                                    </button>
                                    <input
                                        id="fileid"
                                        type="file"
                                        name="upload"
                                        ref="upload"
                                        size="100000"
                                        @change="uploadFile"
                                        hidden
                                        required
                                        accept=".png, .jpg, .jpeg"
                                    />

                                    <div class="img-container-small d-flex justify-content-center align-items-center">
                                        <img
                                            v-if="!uploadedImg && !showImgLoader && !isSvgPresent"
                                            src="@/assets/px/drag-and-drop/dummyUploadImage.svg"
                                        />

                                        <img
                                            v-if="!showImgLoader && isSvgPresent"
                                            :src="svgToBase64"
                                            alt="image not found"
                                        />
                                        <img
                                            v-if="uploadedImg && !showImgLoader && !isSvgPresent"
                                            :src="uploadedImg"
                                            alt="image not uploaded"
                                        />

                                        <div class="d-flex flex-column" v-if="showImgLoader" style="margin-top: 15px">
                                            <div
                                                class="d-flex justify-content-center align-items-center img-loader-main"
                                            >
                                                <b-spinner variant="primary" class="img-loader"></b-spinner>
                                            </div>
                                            <span class="img-loader-text">Uploading...</span>
                                        </div>
                                        <ejs-tooltip
                                            v-if="uploadedImg || svgImg"
                                            class="tooltipcontainer"
                                            content="Delete"
                                            target="#target"
                                            cssClass="customtooltip"
                                            position="top"
                                        >
                                            <span
                                                id="target"
                                                class="material-symbols-outlined img-delete-small"
                                                role="button"
                                                @click="resetFileUpload()"
                                            >
                                                delete
                                            </span>
                                        </ejs-tooltip>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="!['kiosk', 'mobex-health-home'].includes(applicationFrame)"
                                class="scroll-wrapper"
                            >
                                <div style="gap: 25px" class="scroll-container">
                                    <div v-for="bg in genericModulePhotos" :key="bg.image">
                                        <input type="radio" hidden />
                                        <label>
                                            <img
                                                :src="bg.image"
                                                style="width: 50px; height: 50px; max-width: 100px; cursor: pointer"
                                                :class="bg.file_id == backgroundPhoto.file_id ? 'active-bg' : ''"
                                                @click="selectBackground(bg)"
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="['kiosk', 'mobex-health-home'].includes(applicationFrame)"
                                class="d-flex flex-wrap px-4 my-4"
                                style="flex-direction: column; gap: 5px"
                            >
                                <div class="d-flex color-box w-100">
                                    <div class="d-flex my-2 mx-4 w-100">
                                        <div class="d-flex align-items-center w-40" style="gap: 1%">
                                            <input
                                                type="radio"
                                                name="color_type_kiosk"
                                                id="primary_colors"
                                                value="primary_colors"
                                                style="transform: scale(0.8)"
                                                v-model="functionInfo.selectedSchemeOption"
                                                @change="selectColorScheme($event)"
                                            />
                                            <label for="option2" style="font-weight: bold">Primary Color Option</label>
                                        </div>
                                        <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                            <div
                                                v-for="(value, index) in colorScheme.primary_colors"
                                                class="d-flex align-items-center px-2 w-25"
                                                :key="index"
                                                :style="{
                                                    height: '30px',
                                                    width: '50%',
                                                    background: value,
                                                    color: '#FFFFFF',
                                                    'font-size': '12px',
                                                    border: '2px solid black',
                                                    'text-transform': 'uppercase',
                                                }"
                                            >
                                                {{ value }}
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div class="d-flex color-box w-100">
                                    <div class="d-flex my-2 mx-4 w-100">
                                        <div class="d-flex align-items-center w-40" style="gap: 1%">
                                            <input
                                                type="radio"
                                                name="color_type_kiosk"
                                                id="secondary_colors"
                                                value="secondary_colors"
                                                style="transform: scale(0.8)"
                                                v-model="functionInfo.selectedSchemeOption"
                                                @change="selectColorScheme($event)"
                                            />
                                            <label for="option2" style="font-weight: bold"
                                                >Secondary Color Option</label
                                            >
                                        </div>
                                        <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                            <div
                                                v-for="(value, index) in colorScheme.secondary_colors"
                                                class="d-flex align-items-center px-2 w-25"
                                                :key="index"
                                                :style="{
                                                    height: '30px',
                                                    width: '50%',
                                                    background: value,
                                                    color: '#FFFFFF',
                                                    'font-size': '12px',
                                                    border: '2px solid black',
                                                    'text-transform': 'uppercase',
                                                }"
                                            >
                                                {{ value }}
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div v-if="isCustosmizedThemeSelected" class="d-flex color-box w-100">
                                    <div class="d-flex my-2 mx-4 w-100">
                                        <div class="d-flex align-items-center w-40" style="gap: 1%">
                                            <input
                                                type="radio"
                                                name="color_type_kiosk"
                                                id="customized_colors"
                                                value="customized_colors"
                                                style="transform: scale(0.8)"
                                                v-model="functionInfo.selectedSchemeOption"
                                                @change="selectColorScheme($event)"
                                            />
                                            <label for="option2" style="font-weight: bold">Custom Color Option</label>
                                        </div>
                                        <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                            <div
                                                v-for="(value, index) in colorScheme.customized_colors"
                                                class="d-flex align-items-center px-2 w-25"
                                                :key="index"
                                                :style="{
                                                    height: '30px',
                                                    width: '50%',
                                                    background: value,
                                                    color: '#FFFFFF',
                                                    'font-size': '12px',
                                                    border: '2px solid black',
                                                    'text-transform': 'uppercase',
                                                }"
                                            >
                                                {{ value }}
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div class="d-flex color-box w-100">
                                    <div class="d-flex my-2 mx-4 w-100">
                                        <div class="d-flex align-items-center w-40" style="gap: 1%">
                                            <input
                                                type="radio"
                                                name="color_type_kiosk"
                                                id="module_customized_colors"
                                                value="module_customized_colors"
                                                style="transform: scale(0.8)"
                                                v-model="functionInfo.selectedSchemeOption"
                                                @change="selectColorScheme($event)"
                                            />
                                            <label for="option2" style="font-weight: bold"
                                                >Custom Module Color Option</label
                                            >
                                        </div>
                                        <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                            <div
                                                v-for="(value, index) in colorScheme.module_customized_colors"
                                                class="d-flex align-items-center px-2 w-25"
                                                :key="index"
                                            >
                                                <input
                                                    v-if="
                                                        functionInfo.selectedSchemeOption == 'module_customized_colors'
                                                    "
                                                    type="color"
                                                    name=""
                                                    id=""
                                                    v-model="colorScheme.module_customized_colors[index]"
                                                    @change="selectCustomColorScheme($event, index)"
                                                    class="w-100 p-0"
                                                />
                                                <div v-else class="default-color"></div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="mx-5">
                                <div class="mb-4 d-flex w-100">
                                    <Input
                                        label="Select Button Color"
                                        labelClass="me-3 d-flex justify-content-end add-new-app-label w-100"
                                        type="color"
                                        class="field-container d-flex align-items-center w-50"
                                        inputClass="w-100 pp-5 p-1"
                                        name="custom-color"
                                        @change="functionInfo.color = $event.target.value"
                                        v-model="functionInfo.color"
                                    />

                                    <Input
                                        label="Select Text Color"
                                        labelClass="me-3 d-flex justify-content-end add-new-app-label w-100"
                                        type="color"
                                        class="field-container d-flex align-items-center w-50"
                                        inputClass="w-100 pp-5 p-1"
                                        name="custom-text-color"
                                        @change="functionInfo.textColor = $event.target.value"
                                        v-model="functionInfo.textColor"
                                    />
                                </div>
                            </div>
                            <div class="mx-5 px-3">
                                <Input
                                    label="Title"
                                    labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                    type="text"
                                    class="field-container d-flex align-items-center mb-4"
                                    name="title"
                                    placeholder="Enter Title"
                                    v-model="functionInfo.title"
                                    required
                                />
                                <span class="field-container d-flex align-items-center mt-2 mb-4">
                                    <label
                                        for="category"
                                        class="me-3 d-flex add-new-app-label justify-content-end"
                                        style="width: 28%"
                                        >Category</label
                                    >
                                    <Multiselect
                                        id="category"
                                        track-by="id"
                                        label="title"
                                        class="w-70"
                                        placeholder="Search or Select Category"
                                        v-model="categoryInfo"
                                        :options="categoryList"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="functionInfo.module_category = $event.id"
                                    />
                                </span>
                            </div>
                        </div>
                        <div class="section-footer add-new-app-form my-4">
                            <div class="d-flex justify-content-center">
                                <button
                                    v-if="!isEditModule"
                                    type="button"
                                    class="primary btn-save-application"
                                    :disabled="showImgLoader"
                                    @click="addNewApplication"
                                >
                                    Save
                                </button>
                                <button
                                    v-if="isEditModule"
                                    type="button"
                                    class="primary btn-save-application"
                                    :disabled="showImgLoader"
                                    @click="updateApplication(currentModuleId)"
                                >
                                    Save
                                </button>
                                <button type="button" class="no-bg text-button btn-cancel px-4" @click="resetAll()">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </template>
                </form>
            </ValidationObserver>
        </div>

        <div class="modal-list-third-party-app">
            <ValidationObserver ref="formSubMilestone">
                <form style="box-shadow: none">
                    <template v-if="!showLoader && showLinkForm && !showMainForm">
                        <div class="section-body add-new-app-form">
                            <Input
                                label="Title"
                                labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                type="text"
                                class="field-container d-flex align-items-center mb-4"
                                name="title"
                                placeholder="Enter Title here"
                                v-model="subMilestone.description"
                                required
                            />
                        </div>
                        <div class="section-footer add-new-app-form my-4">
                            <div class="d-flex justify-content-center">
                                <button
                                    v-if="!isEditSubMilestone"
                                    type="button"
                                    class="primary btn-save-application"
                                    :disabled="showImgLoader"
                                    @click="addNewSubMilestone"
                                >
                                    Save
                                </button>
                                <button
                                    v-if="isEditSubMilestone"
                                    type="button"
                                    class="primary btn-save-application"
                                    :disabled="showImgLoader"
                                    @click="updateSubMilestone(currentSubMilestoneId)"
                                >
                                    Save
                                </button>
                                <button
                                    type="button"
                                    class="no-bg text-button btn-cancel px-4"
                                    @click="showPage('FUNCTION_LISTING')"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </template>
                </form>
            </ValidationObserver>
        </div>

        <!-- Select SVG Modal -->
        <div class="modal-list-third-party-app">
            <ValidationObserver ref="svgForm">
                <form style="box-shadow: none" class="p-0">
                    <template v-if="!showLoader && showSelectSvgModal">
                        <div
                            v-if="svgList.length"
                            class="d-flex flex-wrap gap-3 m-3 p-3 overflow-auto"
                            style="max-height: 350px; min-height: 200px; padding: 5px; padding-bottom: 10px"
                        >
                            <div
                                v-for="(svg, index) in svgList"
                                :key="index"
                                :class="(selectedSvg.id === svg.id ? 'active-bg ' : '') + 'img-container'"
                                style="max-height: 50px"
                                @click="selectedSvg = svg"
                            >
                                <ejs-tooltip
                                    target="#target"
                                    :content="svg.title"
                                    class="tooltipcontainer"
                                    cssClass="customtooltip"
                                >
                                    <img id="target" :src="svg.imageUrl" alt="" class="img" style="width: 50px" />
                                </ejs-tooltip>
                            </div>
                        </div>
                        <div v-else-if="searchedSvg !== ''" class="w-100">
                            <div
                                class="d-flex justify-content-center align-items-center py-5"
                                style="min-height: 200px"
                            >
                                <h4>No SVG available</h4>
                            </div>
                        </div>
                        <div v-else class="w-100">
                            <div
                                class="d-flex justify-content-center align-items-center py-5"
                                style="min-height: 200px"
                            >
                                <h4>No SVG Added</h4>
                            </div>
                        </div>
                        <div class="section-footer add-new-app-form my-4">
                            <div class="d-flex justify-content-center">
                                <button type="button" class="primary btn-save-application" @click="saveSvg">
                                    Save
                                </button>
                                <button
                                    type="button"
                                    class="no-bg text-button btn-cancel px-4"
                                    @click="showSelectSvgModal = false"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </template>
                </form>
            </ValidationObserver>
        </div>

        <div v-if="showLoader" class="d-flex flex-column justify-content-center align-items-center loader-main">
            <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
            <span class="ms-3">Loading ...</span>
        </div>
    </NoButtonModal>
</template>

<script>
    import draggable from 'vuedraggable';
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import SpinLoader from '@/components/general/loading/SpinLoader.vue';
    import { ValidationObserver } from 'vee-validate';
    import { memberProfile, dashboardBuilder, svgPicker, trackMilestones } from '@/util/apiRequests';
    import { category } from '../../util/apiRequests';

    const initialFunctionInfoState = {
        title: '',
        image: '',
        color: '',
        textColor: '',
        module_category: null,
    };

    const initialMilestoneState = {
        description: '',
        type: 'General',
        status: 'Pending',
        attachments: [],
    };

    const initialSubMilestoneState = {
        description: '',
        type: 'General',
        status: 'Pending',
    };

    const initialMilestoneAttachement = {
        title: '',
        link: '',
        type: '',
    };

    const attachmentsTypes = [
        {
            type: 'WebView',
            name: 'Web Link',
        },
        {
            type: 'PDF',
            name: 'PDF',
        },
    ];

    export default {
        name: 'MilestoneEditModal',
        components: { NoButtonModal, ValidationObserver, SpinLoader, draggable },
        props: {
            open: {
                type: Boolean,
                required: true,
            },
            position: {
                type: String,
                required: true,
            },
            pageData: {
                type: Object,
            },
            applicationFrame: {
                type: String,
                required: true,
            },
            current_template: {
                type: Number,
            },
            group: {
                type: String,
            },
        },
        data() {
            return {
                showAppListing: true,
                showLinkListing: true,
                showLoader: true,
                isEditModule: false,
                isEditMilestone: false,
                isEditSubMilestone: false,
                milestonesList: [],
                subMilestonesList: [],
                functionInfo: { ...initialFunctionInfoState },
                milestone: { ...initialMilestoneState },
                subMilestone: { ...initialSubMilestoneState },
                uploadedImg: '',
                showImgLoader: false,
                showMainForm: true,
                color: null,
                textColor: null,
                showLinkForm: false,
                colorScheme: {},
                isCustosmizedThemeSelected: false,
                isModuleLogoUpdated: false,
                svgImg: '',
                svgElement: '',
                currentModuleId: '',
                showSelectSvgModal: false,
                currentMilestoneId: '',
                currentSubMilestoneId: '',
                backgroundPhoto: {},
                Position: null,
                isSvgPresent: false,
                dashboard_id: null,
                allSvgList: [],
                svgList: [],
                selectedSvg: {},
                searchedSvg: '',
                genericModulePhotos: [],
                addMilestoneAttachment: false,
                attachmentsType: '',
                attachmentsTypes,
                milestoneAttachment: { ...initialMilestoneAttachement },
                clickedProfileFunction: 'Track Milestone',
                categoryInfo: '',
                categoryList: [],
            };
        },
        computed: {
            svgToBase64() {
                if (!this.svgImg) {
                    return;
                }

                // Get the outer HTML representation of the SVG element
                const svgHtml = this.svgImg.outerHTML;

                // Encode the SVG HTML string to base64
                const base64Svg = btoa(svgHtml);

                // Return the base64 encoded SVG data
                return 'data:image/svg+xml;base64,' + base64Svg;
            },
        },
        methods: {
            selectBackground(bg) {
                this.backgroundPhoto = {
                    file_id: bg.file_id,
                    id: bg.id,
                    image: bg.image,
                };

                this.isSvgPresent = false;
                this.uploadedImg = this.backgroundPhoto.image;
                this.functionInfo.image = this.backgroundPhoto.file_id;
            },
            chooseFile() {
                if (this.$refs.upload != undefined) {
                    this.$refs.upload.click();
                }
            },
            isFileAvailable() {
                return this.$refs.upload.value === '' ? false : true;
            },
            async getGenericImages() {
                const endpoint = dashboardBuilder.getAssets('cde_generic_modules');
                const assetsResponse = await this.$api.get(endpoint);

                if (!assetsResponse.data.success) {
                    throw assetsResponse.data.error;
                }

                this.genericModulePhotos = assetsResponse.data.list;
                return true;
            },
            async geturl(fileId) {
                const endpoint = dashboardBuilder.getUrls();
                const requestData = {
                    fileIds: [fileId],
                };
                let response = await this.$api.post(endpoint, requestData);
                return response?.data?.data[0].image;
            },
            async addNewApplication() {
                try {
                    const form = this.$refs.form;
                    const isFormValid = await form.validate();

                    if (!isFormValid) {
                        this.$toasted.error('Please fill all required fields');
                        return;
                    }

                    if (!this.isFileAvailable() && !this.functionInfo.image.length) {
                        this.$toasted.error('Please upload or select Image');
                        return;
                    }

                    this.showLoader = true;

                    if (this.isSvgPresent) {
                        let isImageUploaded = await this.uploadModuleLogo();

                        if (!isImageUploaded) {
                            return;
                        }
                    }

                    this.functionInfo = {
                        ...this.functionInfo,
                        assigned_to: this.clickedProfileFunction,
                        position: this.Position,
                        dashboard_assigned: this.dashboard_id,
                        issaved: 0,
                        application: this.applicationFrame,
                        isMaster: this.pageData?.page2?.isMaster,
                        section: this.group === 'LI' ? 'Upper' : 'Lower',
                    };

                    if (this.applicationFrame !== 'mobex-health-home') {
                        delete this.functionInfo.section;
                    }

                    const endpoint = memberProfile.createGenericModule();
                    const saveFunctionResponse = await this.$api.post(endpoint, this.functionInfo);
                    if (!saveFunctionResponse.data.success) {
                        throw saveFunctionResponse.data.error;
                    }

                    const { template } = this.pageData.page4;
                    this.template = template;

                    if (this.applicationFrame === 'mobex-health-home') {
                        Object.keys(template).forEach((group) => {
                            if (group === this.group) {
                                Object.keys(template[group]).forEach(async (item) => {
                                    if (item == group + ' ' + this.Position) {
                                        if (this.functionInfo.color != '') {
                                            template[group][item].style = template[group][item].style.replace(
                                                /background-color:[^;]*/,
                                                `background-color:${this.functionInfo.color};`
                                            );
                                        } else {
                                            template[group][item].style = template[group][item].style.replace(
                                                /background-color:[^;]*/,
                                                `background-color:${
                                                    this.pageData.page3.colorScheme[
                                                        this.pageData.page3.colorScheme.selectedOption
                                                    ][0]
                                                };`
                                            );
                                        }

                                        if (this.functionInfo.textColor != '') {
                                            template[group][item].style = template[group][item].style.replace(
                                                /(?:^|;)\s*color\s*:[^;]*/,
                                                `color:${this.functionInfo.textColor}`
                                            );
                                        } else {
                                            template[group][item].style = template[group][item].style.replace(
                                                /(?:^|;)\s*color\s*:[^;]*/,
                                                `color:${
                                                    this.pageData.page3.colorScheme[
                                                        this.pageData.page3.colorScheme.selectedOption
                                                    ][1]
                                                }`
                                            );
                                        }

                                        template[group][item].primaryColor = this.functionInfo.primaryColor;
                                        template[group][item].secondaryColor = this.functionInfo.secondaryColor;

                                        template[group][item].title = this.functionInfo.title;

                                        if (this.functionInfo.image) {
                                            template[group][item].elements[0].image_id = this.functionInfo.image;
                                            let res = await this.geturl(this.functionInfo.image);
                                            template[group][item].elements[0].image = res;
                                        }
                                    }
                                });
                            }
                        });
                    } else {
                        Object.keys(this.template).forEach((group) => {
                            if (group === 'LI') {
                                Object.keys(this.template[group]).forEach(async (li) => {
                                    if (li == 'LI ' + this.Position) {
                                        this.template[group][li].title = this.functionInfo?.title;
                                        this.template[group][li].color = this.functionInfo.color;
                                        this.template[group][li].textColor = this.functionInfo.textColor;
                                        if (this.functionInfo.image) {
                                            this.template[group][li].elements[0].image_id = this.functionInfo.image;
                                            let res = await this.geturl(this.functionInfo.image);
                                            this.template[group][li].elements[0].image = res;
                                        }
                                        this.template[group][li].elements[0].id = saveFunctionResponse.data.response[0];
                                    }
                                });
                            }
                        });
                    }

                    this.$toasted.success('Module added successfully !');
                    this.resetForm();
                    this.$emit('close');
                } catch (e) {
                    const errorMesage = 'Please enter all required fields';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.showLoader = false;
                }
            },
            async updateApplication(id) {
                try {
                    const form = this.$refs.form;
                    const isFormValid = await form.validate();

                    if (!isFormValid) {
                        this.$toasted.error('Please fill all required fields');
                        return;
                    }

                    if (!this.isFileAvailable() && !this.functionInfo.image.length) {
                        this.$toasted.error('Please upload or select Image');
                        return;
                    }

                    this.showLoader = true;

                    const { template } = this.pageData.page4;

                    this.template = template;

                    if (this.isSvgPresent && this.isModuleLogoUpdated) {
                        let isImageUploaded = await this.uploadModuleLogo('main-module-image');

                        if (!isImageUploaded) {
                            return;
                        }
                    }

                    this.functionInfo = {
                        ...this.functionInfo,
                        dashboard_assigned: this.dashboard_id,
                        application: this.applicationFrame,
                        generic_function: this.clickedProfileFunction,
                        isMaster: this.pageData?.page2?.isMaster,
                        section: this.group === 'LI' ? 'Upper' : 'Lower',
                    };

                    if (this.applicationFrame !== 'mobex-health-home') {
                        delete this.functionInfo.section;
                    }

                    const endpoint = memberProfile.updateGenericModule(id);
                    const saveFunctionResponse = await this.$api.put(endpoint, this.functionInfo);

                    if (!saveFunctionResponse.data.success) {
                        throw saveFunctionResponse.data.error;
                    }

                    if (this.applicationFrame === 'mobex-health-home') {
                        Object.keys(template).forEach((group) => {
                            if (group === this.group) {
                                Object.keys(template[group]).forEach(async (item) => {
                                    if (item == group + ' ' + this.Position) {
                                        if (this.functionInfo.color != '') {
                                            template[group][item].style = template[group][item].style.replace(
                                                /background-color:[^;]*/,
                                                `background-color:${this.functionInfo.color};`
                                            );
                                        } else {
                                            template[group][item].style = template[group][item].style.replace(
                                                /background-color:[^;]*/,
                                                `background-color:${
                                                    this.pageData.page3.colorScheme[
                                                        this.pageData.page3.colorScheme.selectedOption
                                                    ][0]
                                                };`
                                            );
                                        }

                                        if (this.functionInfo.textColor != '') {
                                            template[group][item].style = template[group][item].style.replace(
                                                /(?:^|;)\s*color\s*:[^;]*/,
                                                `color:${this.functionInfo.textColor}`
                                            );
                                        } else {
                                            template[group][item].style = template[group][item].style.replace(
                                                /(?:^|;)\s*color\s*:[^;]*/,
                                                `color:${
                                                    this.pageData.page3.colorScheme[
                                                        this.pageData.page3.colorScheme.selectedOption
                                                    ][1]
                                                }`
                                            );
                                        }

                                        template[group][item].primaryColor = this.functionInfo.primaryColor;
                                        template[group][item].secondaryColor = this.functionInfo.secondaryColor;

                                        template[group][item].title = this.functionInfo.title;

                                        if (this.functionInfo.image) {
                                            template[group][item].elements[0].image_id = this.functionInfo.image;
                                            let res = await this.geturl(this.functionInfo.image);
                                            template[group][item].elements[0].image = res;
                                        }
                                    }
                                });
                            }
                        });
                    } else {
                        Object.keys(this.template).forEach((group) => {
                            if (group === 'LI') {
                                Object.keys(this.template[group]).forEach(async (li) => {
                                    if (li == 'LI ' + this.Position) {
                                        this.template[group][li].title = this.functionInfo?.title;
                                        this.template[group][li].color = this.functionInfo.color;
                                        this.template[group][li].textColor = this.functionInfo.textColor;
                                        if (this.functionInfo.image) {
                                            this.template[group][li].elements[0].image_id = this.functionInfo.image;
                                            let res = await this.geturl(this.functionInfo.image);
                                            this.template[group][li].elements[0].image = res;
                                        }
                                    }
                                });
                            }
                        });
                    }

                    this.$toasted.success('Module updated successfully !');
                    this.resetForm();
                    this.$emit('close');
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to update module !';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.showLoader = false;
                }
            },
            async updateMilestone(id) {
                try {
                    const form = this.$refs.formMilestone;
                    const isFormValid = await form.validate();
                    if (!isFormValid) {
                        this.$toasted.error('Please fill all required fields');
                        return;
                    }
                    this.showLoader = true;
                    if (this.addMilestoneAttachment) {
                        if (this.milestone.attachments.length) {
                            this.milestone.attachments[0] = { ...this.milestoneAttachment };
                        } else {
                            this.milestone.attachments.push(this.milestoneAttachment);
                        }
                    }
                    const milestone = { ...this.milestone };
                    delete milestone.id;
                    delete milestone.module_id;
                    delete milestone.submilestones;
                    delete milestone.defaulttype;
                    const endpoint = trackMilestones.updateMilestone(id);
                    const saveFunctionResponse = await this.$api.put(endpoint, milestone);
                    if (!saveFunctionResponse.data.success) {
                        throw saveFunctionResponse.data.error;
                    }

                    this.$toasted.success('Milestone updated successfully !');
                    this.showPage('FUNCTION_LISTING');
                    this.resetMilestoneForm();
                    this.resetMilestoneAttachments();
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to update milestone !';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.showLoader = false;
                }
            },
            async addMilestone() {
                try {
                    const form = this.$refs.formMilestone;
                    const isFormValid = await form.validate();
                    if (!isFormValid) {
                        this.$toasted.error('Please fill all required fields');
                        return;
                    }

                    this.showLoader = true;

                    if (this.addMilestoneAttachment) {
                        this.milestone.attachments.push(this.milestoneAttachment);
                    }

                    const milestone = {
                        ...this.milestone,
                        dashboard_id: this.dashboard_id,
                        module_id: this.currentModuleId,
                    };

                    const endpoint = trackMilestones.createMilestone();
                    const saveFunctionResponse = await this.$api.post(endpoint, milestone);

                    if (!saveFunctionResponse.data.success) {
                        throw saveFunctionResponse.data.error;
                    }

                    this.$toasted.success('Milestone added successfully !');
                    this.showPage('FUNCTION_LISTING');
                    this.resetMilestoneForm();
                    this.resetMilestoneAttachments();
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to add milestone !';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.showLoader = false;
                }
            },
            async getCategories() {
                try {
                    const endpoint = category.getCategories(this.$store.state.user.company_id);
                    const response = await this.$api.get(endpoint);
                    this.categoryList = response.data ?? [];
                } catch (err) {
                    this.$toasted.error('Failed to fetch Categories.');
                }
            },
            async fetchApplications() {
                try {
                    this.showLoader = true;

                    // HTTP call for get specific 3rd party application detail
                    const endpoint = memberProfile.getSpecificGenericFunction(
                        this.clickedProfileFunction,
                        this.Position,
                        this.dashboard_id,
                        this.applicationFrame,
                        this.group === 'LI' ? 'Upper' : 'Lower'
                    );
                    const functionResponse = await this.$api.get(endpoint);

                    if (!functionResponse) {
                        throw 'e';
                    }

                    let { info } = functionResponse.data;
                    if (info.length) {
                        this.functionInfo.title = info[0].title;
                        this.functionInfo.image = info[0].image;
                        this.functionInfo.color = info[0].color;
                        this.functionInfo.textColor = info[0].textColor;
                        this.functionInfo.primaryColor = info[0].primaryColor;
                        this.functionInfo.secondaryColor = info[0].secondaryColor;
                        this.functionInfo.selectedSchemeOption = info[0]?.selectedSchemeOption;
                        this.functionInfo.module_category = info[0].module_category;
                        this.uploadedImg = info[0].imageUrl;
                        this.currentModuleId = info[0].id;
                        this.isEditModule = true;

                        this.categoryInfo = this.categoryList.find(
                            (category) => category.id === info[0].module_category
                        );

                        if (this.functionInfo.selectedSchemeOption == 'module_customized_colors') {
                            this.colorScheme = {
                                ...this.colorScheme,
                                selectedOption: 'module_customized_colors',
                                module_customized_colors: [
                                    info[0].primaryColor,
                                    info[0].secondaryColor,
                                    info[0].color,
                                    info[0].textColor,
                                ],
                            };
                        }
                        if (this.functionInfo?.image) {
                            let fileExtension = this.functionInfo.image?.split('.')[1];
                            if (['svg', 'svg+xml'].includes(fileExtension)) {
                                await this.urlToSvg(this.uploadedImg);
                            }
                        }
                        await this.getMilestones();
                    }
                } catch (err) {
                    this.$toasted.error('Failed to fetch module details');
                } finally {
                    this.showLoader = false;
                }
            },
            async getMilestones() {
                try {
                    const endpoint = trackMilestones.getMilestonesByDashboardId(
                        this.dashboard_id,
                        this.currentModuleId
                    );
                    const response = await this.$api.get(endpoint);
                    if (response.data.success) {
                        this.milestonesList = response.data.message;
                    }
                } catch (err) {
                    this.$toasted.error('Failed to fetch milestones');
                }
            },
            async editMilestone(milestone) {
                try {
                    this.showLoader = true;
                    this.showMainForm = false;
                    this.currentMilestoneId = milestone.id;
                    this.isEditMilestone = true;
                    const data = { ...milestone };

                    if (!data) throw new Error('No data found'); // Early exit if no data is returned

                    this.milestone = { ...data };
                    const attachments = JSON.parse(data.attachments || '[]');

                    this.milestone.attachments = [...attachments];

                    if (attachments.length) {
                        this.milestoneAttachment = { ...attachments[0] };
                        this.attachmentsType = this.attachmentsTypes.find(
                            (item) => item.type === this.milestoneAttachment.type
                        );
                        this.addMilestoneAttachment = true;
                    } else {
                        this.addMilestoneAttachment = false;
                    }

                    console.log(this.milestone);
                } catch (err) {
                    this.$toasted.error('Failed to fetch milestone details');
                } finally {
                    this.showLoader = false;
                }
            },
            async editSubMilestone(subMilestone) {
                try {
                    this.showLoader = true;
                    this.currentSubMilestoneId = subMilestone.id;
                    this.subMilestone = { ...subMilestone };
                    this.isEditSubMilestone = true;
                    this.showLoader = false;
                } catch (err) {
                    this.$toasted.error('Failed to fetch sub milestone details');
                }
            },
            async deleteMilestone(id) {
                try {
                    this.showLoader = true;
                    const endpoint = trackMilestones.deleteMilestone(id);
                    const saveFunctionResponse = await this.$api.delete(endpoint);
                    if (!saveFunctionResponse.data.success) {
                        throw saveFunctionResponse.data.error;
                    }

                    this.$toasted.success('Milestone deleted successfully !');
                    this.showPage('FUNCTION_LISTING');
                    this.resetForm();
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to delete milestone !';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.showLoader = false;
                }
            },
            async uploadFile(event) {
                if (event.target.files.length > 0) {
                    const file = event.target.files[0];
                    const fileName = file.name;
                    const fileSize = file.size;
                    let fileNameSplit = fileName.split('.');
                    let fileExtension = fileNameSplit[1];

                    if (!['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG'].includes(fileExtension)) {
                        this.$toasted.error('Only png, jpg/jpeg files are allowed');
                        this.$refs.upload.value = '';
                        return;
                    }

                    if (fileSize / 1024 / 1024 > 10) {
                        this.$toasted.error('Image size should be less than 10MB');
                        this.$refs.upload.value = '';
                        return;
                    }

                    this.showImgLoader = true;

                    try {
                        // Wrap FileReader in a Promise
                        const readerResult = await new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onload = (event) => resolve(event.target.result);
                            reader.onerror = (error) => reject(error);
                            reader.readAsDataURL(file);
                        });

                        this.uploadedImg = readerResult;

                        if (fileExtension === 'svg') {
                            this.svgImg = this.base64ToSvg(this.uploadedImg);
                            this.svgElement = this.svgImg;
                            this.renderSvg(this.svgImg, false);
                            this.isSvgPresent = true;
                        } else {
                            const formData = new FormData();
                            formData.append('file', file);
                            const endpoint = memberProfile.uploadProfileFunctionsImage();
                            const uploadImgResponse = await this.$api.post(endpoint, formData);

                            if (!uploadImgResponse.data.success) {
                                throw uploadImgResponse.data.error;
                            }

                            this.functionInfo.image = uploadImgResponse.data.s3_link;
                            this.isSvgPresent = false;
                        }

                        this.backgroundPhoto = {};
                        this.isModuleLogoUpdated = true;
                        this.$toasted.success('Application image uploaded successfully!');
                    } catch (error) {
                        const errorMessage = error || 'Image upload failed!';
                        this.$toasted.error(errorMessage);
                        this.$refs.upload.value = '';
                    } finally {
                        this.showImgLoader = false;
                    }
                }
            },
            base64ToSvg(base64String) {
                const base64Data = base64String.replace(/^data:image\/svg\+xml;base64,/, '');
                const svgXml = atob(base64Data);
                return svgXml;
            },
            async urlToSvg(url, fetchedImg = true) {
                const reader = new FileReader();
                let img = await fetch(url);
                let imgBlob = await img.blob();
                reader.readAsDataURL(imgBlob);

                reader.onload = () => {
                    const base64Data = reader.result.replace(/^data:image\/svg\+xml;base64,/, '');
                    this.svgImg = atob(base64Data);
                    this.svgElement = this.svgImg;
                    this.renderSvg(this.svgImg, false, fetchedImg);
                    this.isSvgPresent = true;
                };
            },
            renderSvg(svgXml, isColorChanged, fetchImage = false) {
                const primaryColor = this.functionInfo.primaryColor;
                const secondaryColor = this.functionInfo.secondaryColor;

                if (!primaryColor || !secondaryColor) {
                    return;
                }

                // Parse SVG XML string
                const parser = new DOMParser();
                const svgDoc = parser.parseFromString(svgXml, 'image/svg+xml');

                // Get the SVG element
                const svgElement = svgDoc.documentElement;

                if (fetchImage) {
                    this.svgImg = svgElement;
                    return;
                }

                const allPaths = svgElement.querySelectorAll('path');

                allPaths.forEach((path) => {
                    if (path.hasAttribute('fill')) {
                        let color = path.getAttribute('fill').split('#')[1].toLowerCase();
                        let colorTypeAvailable = false;
                        if (isColorChanged) {
                            let colorType = path.getAttribute('id');
                            if (colorType === 'primaryColor') {
                                path.setAttribute('fill', primaryColor);
                                colorTypeAvailable = true;
                            } else if (colorType === 'secondaryColor') {
                                path.setAttribute('fill', secondaryColor);
                                colorTypeAvailable = true;
                            }
                        }
                        if (!colorTypeAvailable) {
                            if (
                                (color[0] === 'a' ||
                                    color[0] === 'b' ||
                                    color[0] === 'c' ||
                                    color[0] === 'd' ||
                                    color[0] === 'e' ||
                                    color[0] === 'f') &&
                                (color[2] === 'a' ||
                                    color[2] === 'b' ||
                                    color[2] === 'c' ||
                                    color[2] === 'd' ||
                                    color[2] === 'e' ||
                                    color[2] === 'f') &&
                                (color[4] === 'a' ||
                                    color[4] === 'b' ||
                                    color[4] === 'c' ||
                                    color[4] === 'd' ||
                                    color[4] === 'e' ||
                                    color[4] === 'f')
                            ) {
                                path.setAttribute('id', 'primaryColor');
                                path.setAttribute('fill', primaryColor);
                            } else {
                                path.setAttribute('id', 'secondaryColor');
                                path.setAttribute('fill', secondaryColor);
                            }
                        }
                    }
                });

                this.svgImg = svgElement;
            },
            async updateSubMilestone(id) {
                try {
                    const form = this.$refs.formSubMilestone;
                    const isFormValid = await form.validate();
                    if (!isFormValid) {
                        this.$toasted.error('Please fill all required fields');
                        return;
                    }
                    this.showLoader = true;
                    const submilestones = { ...this.subMilestone };
                    delete submilestones.id;
                    const endpoint = trackMilestones.updateSubMilestone(id);
                    const saveFunctionResponse = await this.$api.put(endpoint, submilestones);
                    if (!saveFunctionResponse.data.success) {
                        throw saveFunctionResponse.data.error;
                    }

                    const endpoint1 = trackMilestones.getSubMilestones(this.currentMilestoneId);
                    const res = await this.$api.get(endpoint1);
                    if (!res) {
                        throw 'e';
                    }
                    this.subMilestonesList = res.data.message;

                    this.$toasted.success('Sub Milestone updated successfully !');
                    this.showPage('LINK_LISTING');
                    this.resetSubMilestoneForm();
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to update sub milestone !';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.showLoader = false;
                }
            },
            resetFileUpload() {
                this.$refs.upload.value = '';
                this.uploadedImg = '';
                this.functionInfo.image = '';
                this.svgImg = '';
                this.isSvgPresent = false;
            },
            async showPage(pageType) {
                if (pageType == 'ADD_MILESTONE') {
                    this.isSvgPresent = false;
                    this.showAppListing = false;
                    this.uploadedImg = '';
                    this.showMainForm = false;
                    this.resetMilestoneForm();
                    this.resetMilestoneAttachments();
                } else if (pageType === 'FUNCTION_LISTING') {
                    this.showAppListing = true;
                    this.showMainForm = true;
                    this.isEditModule = false;
                    this.showLinkForm = false;
                    this.isEditMilestone = false;
                    this.subMilestonesList = [];
                    this.resetSubMilestoneForm();
                    this.fetchApplications();
                } else if (pageType === 'LINK_LISTING') {
                    this.showLinkListing = true;
                    this.showAppListing = false;
                    this.showMainForm = false;
                    this.isEditSubMilestone = false;
                    this.addSubMilestone(this.currentMilestoneId);
                } else {
                    this.showAppListing = false;
                    this.showLinkListing = false;
                    this.isEditModule = false;
                    this.uploadedImg = '';
                    this.resetForm();
                    this.resetMilestoneForm();
                    this.resetMilestoneAttachments();
                }
            },
            async addSubMilestone(milestoneid) {
                this.showLinkForm = true;
                this.showMainForm = false;
                this.showLoader = true;
                this.currentMilestoneId = milestoneid;

                const endpoint = trackMilestones.getSubMilestones(milestoneid);
                const response = await this.$api.get(endpoint);
                if (!response) {
                    throw 'e';
                }
                this.subMilestonesList = response.data.message;
                this.showLoader = false;
            },
            selectColorScheme(event) {
                let colors;
                switch (event.target.value) {
                    case 'primary_colors':
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'primary_colors',
                        };
                        colors = this.colorScheme.primary_colors;
                        break;
                    case 'secondary_colors':
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'secondary_colors',
                        };
                        colors = this.colorScheme.secondary_colors;
                        break;
                    case 'customized_colors':
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'customized_colors',
                        };
                        colors = this.colorScheme.customized_colors;
                        break;
                    case 'module_customized_colors':
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'module_customized_colors',
                        };
                        colors = this.colorScheme.module_customized_colors;
                        break;
                    default:
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'primary_colors',
                        };
                        colors = this.colorScheme.primary_colors;
                        break;
                }

                if (event.target.value !== 'module_customized_colors') {
                    this.colorScheme = {
                        ...this.colorScheme,
                        module_customized_colors: ['#FFFFFF', '#FF6600', '#FFFFFF', '#000000'],
                    };
                }

                this.functionInfo.primaryColor = colors[0];
                this.functionInfo.secondaryColor = colors[1];
                this.functionInfo.color = colors[2];
                this.functionInfo.textColor = colors[3];

                if (this.isSvgPresent) {
                    this.renderSvg(this.svgElement, true);
                    this.isModuleLogoUpdated = true;
                }
            },
            selectCustomColorScheme(event, colorNo) {
                const { selectedOption } = this.colorScheme;
                let colors = [...this.colorScheme[selectedOption]];
                colors[colorNo] = event.target.value;

                this.functionInfo.primaryColor = colors[0];
                this.functionInfo.secondaryColor = colors[1];
                this.functionInfo.color = colors[2];
                this.functionInfo.textColor = colors[3];

                if ((colorNo == 0 || colorNo == 1) && this.isSvgPresent) {
                    this.isModuleLogoUpdated = true;
                    this.renderSvg(this.svgElement, true);
                }
            },
            async uploadModuleLogo() {
                try {
                    let filename = 'module-logo.svg';
                    let svgElement = this.svgImg;

                    const svgData = new XMLSerializer().serializeToString(svgElement);

                    const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });

                    const svgFile = new File([svgBlob], filename, { type: 'image/svg+xml' });

                    const formData = new FormData();
                    formData.append('file', svgFile);

                    const endpoint1 = memberProfile.uploadProfileFunctionsImage();
                    const uploadImgResponse = await this.$api.post(endpoint1, formData);

                    if (!uploadImgResponse.data.success) {
                        throw uploadImgResponse.data.error;
                    }

                    this.functionInfo.image = uploadImgResponse.data.s3_link;

                    return true;
                } catch (err) {
                    this.$toasted.error('Failed to upload Image');
                }
                return false;
            },
            async addNewSubMilestone() {
                try {
                    const form = this.$refs.formSubMilestone;
                    const isFormValid = await form.validate();
                    if (!isFormValid) {
                        this.$toasted.error('Please fill all required fields');
                        return;
                    }

                    this.showLoader = true;

                    this.subMilestone = {
                        ...this.subMilestone,
                        parent_milestone_id: this.currentMilestoneId,
                    };
                    const endpoint = trackMilestones.createSubMilestone();
                    const saveFunctionResponse = await this.$api.post(endpoint, this.subMilestone);
                    if (!saveFunctionResponse.data.success) {
                        throw saveFunctionResponse.data.error;
                    }

                    this.$toasted.success('Sub Milestone added successfully !');
                    this.showPage('LINK_LISTING');
                    this.resetSubMilestoneForm();
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to add Sub Milestone !';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.showLoader = false;
                }
            },
            async deleteSubMilestone(id) {
                try {
                    this.showLoader = true;
                    const endpoint = trackMilestones.deleteSubMilestone(id);
                    const saveFunctionResponse = await this.$api.delete(endpoint);
                    if (!saveFunctionResponse.data.success) {
                        throw saveFunctionResponse.data.error;
                    }

                    this.subMilestonesList = this.subMilestonesList.filter((subItem) => subItem.id != id);

                    this.$toasted.success('Sub Milestone deleted successfully !');
                    this.showPage('LINK_LISTING');
                    this.resetForm();
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to delete subMilestone !';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.showLoader = false;
                }
            },
            resetForm() {
                this.functionInfo = { ...initialFunctionInfoState };
                this.uploadedImg = '';
                if (!this.showAppListing) {
                    requestAnimationFrame(() => {
                        this.$refs.form?.reset();
                    });
                }
            },
            resetMilestoneForm() {
                this.milestone = { ...initialMilestoneState };
                this.addMilestoneAttachment = false;
                if (!this.showAppListing) {
                    requestAnimationFrame(() => {
                        this.$refs.formMilestone?.reset();
                    });
                }
            },
            resetSubMilestoneForm() {
                this.subMilestone = { ...initialSubMilestoneState };
                if (!this.showAppListing) {
                    requestAnimationFrame(() => {
                        this.$refs.formSubMilestone?.reset();
                    });
                }
            },
            resetAll() {
                this.resetFileUpload();
                this.resetForm();
                this.resetMilestoneForm();
                this.resetSubMilestoneForm();
                this.resetMilestoneAttachments();
                this.$emit('close');
            },
            resetMilestoneAttachments() {
                this.attachmentsType = '';
                this.milestoneAttachment = { ...initialMilestoneAttachement };
                this.milestone.attachments = [];
            },
            async getSvgList() {
                try {
                    const endpoint = svgPicker.getSvgList();
                    const res = await this.$api.get(endpoint);
                    this.allSvgList = res.data;
                    this.svgList = res.data;
                    this.searchedSvg = '';
                } catch (err) {
                    this.$toasted.error('Failed to fetch SVG List');
                }
            },
            chooseFromCompany() {
                this.showSelectSvgModal = true;
                this.searchedSvg = '';
                this.svgList = this.allSvgList;

                if (this.showMainForm) {
                    const selectedSvgData = this.allSvgList.find((svg) => svg.file_id === this.functionInfo?.image);
                    this.selectedSvg = selectedSvgData ?? {};
                } else {
                    const selectedSvgData = this.allSvgList.find((svg) => svg.file_id === this.milestone?.image);
                    this.selectedSvg = selectedSvgData ?? {};
                }
            },
            handleSearchedSVG() {
                const searchedText = this.searchedSvg?.toLowerCase();
                this.svgList = this.allSvgList.filter(
                    (item) =>
                        item.title.toLowerCase().includes(searchedText) ||
                        item.categories.toLowerCase().includes(searchedText)
                );
            },
            async saveSvg() {
                if (!this.selectedSvg?.file_id) {
                    return this.$toasted.error('Please select any svg image');
                }

                this.showLoader = true;

                const img = await this.geturl(this.selectedSvg?.file_id);
                await this.urlToSvg(img, false);

                if (this.showMainForm) {
                    this.functionInfo.image = this.selectedSvg.file_id;
                } else {
                    this.milestone.image = this.selectedSvg.file_id;
                }

                this.showSelectSvgModal = false;
                this.isModuleLogoUpdated = true;
                this.showLoader = false;
            },
            async handleSubMilestoneReorder() {
                let priorityIndex = 0;

                const submilestones = this.subMilestonesList.map((item) => {
                    return {
                        id: item.id,
                        priority: priorityIndex++,
                    };
                });

                const endpoint = trackMilestones.updatePriorityOfSubMilestone();
                const response = await this.$api.put(endpoint, { submilestones });

                if (!response.data.success) {
                    throw new Error(response.data.error);
                }
            },
        },
        async created() {
            this.dashboard_id = this.$route?.query?.id;
            this.Position = this.position;

            if (this.showAppListing) {
                const { colorScheme } = this.pageData.page3;
                if (['kiosk', 'mobex-health-home'].includes(this.applicationFrame)) {
                    const { selectedOption } = colorScheme;
                    this.colorScheme = {
                        ...colorScheme,
                        module_customized_colors: ['#FFFFFF', '#FF6600', '#FFFFFF', '#000000'],
                    };
                    this.isCustosmizedThemeSelected = colorScheme.selectedOption == 'customized_colors';
                    this.functionInfo.selectedSchemeOption = this.colorScheme.selectedOption;
                    this.functionInfo.primaryColor = colorScheme[selectedOption][0];
                    this.functionInfo.secondaryColor = colorScheme[selectedOption][1];
                    this.color = colorScheme[selectedOption][2];
                    this.textColor = colorScheme[selectedOption][3];
                } else {
                    this.color = colorScheme.colors[0];
                    this.textColor = colorScheme.colors[1];
                }

                this.functionInfo.color = this.color;
                this.functionInfo.textColor = this.textColor;

                await this.getCategories();
                await this.fetchApplications();

                if (['kiosk', 'mobex-health-home'].includes(this.applicationFrame)) {
                    await this.getSvgList();
                } else {
                    await this.getGenericImages();
                }
            }
        },
    };
</script>

<style scoped>
    .loader-main {
        min-height: 50vh;
    }

    .add-list-function-loader {
        height: 2em;
        width: 2em;
        font-weight: bolder;
    }

    .img-loader-main {
        min-height: 30px;
    }

    .pp-5 {
        padding: 5% !important;
    }

    .img-loader {
        height: 0.9em;
        width: 0.9em;
        font-weight: 600;
    }

    .img-loader-text {
        font-size: 0.8em;
        font-weight: 600;
        color: var(--base-theme-color);
    }

    .scroll-wrapper {
        background-color: #b4cff1f6;
        margin-bottom: 5%;
        width: 100%;
        text-align: -webkit-center;
    }

    .scroll-container {
        width: 80%;
        overflow: auto;
        white-space: nowrap;
        padding: 10px;
        display: flex;
        justify-content: space-between;
    }

    .active-bg {
        width: 50px !important;
        height: 50px !important;
        border: 2px solid lime;
        padding: 2px;
    }

    .color {
        accent-color: var(--base-theme-tab-color);
    }

    .img-container {
        padding: 5px;
        border-radius: 10px;
        cursor: pointer;
        box-shadow: 0px 3px 4px 2px rgba(0, 0, 0, 0.1);
    }

    .search-svg-icon {
        background: #f1f7ff;
        border-radius: 10px;
        border: 2px solid #b1d0ff;
    }

    .search-svg-icon input {
        box-shadow: none;
        background: transparent !important;
    }

    .search-svg-icon input:focus-visible,
    .search-svg-icon input:focus {
        outline: none;
    }

    .search-svg-icon input::placeholder {
        color: #919fad;
        font-size: 14px;
    }

    .color-box {
        width: 45%;
        border-radius: 10px;
        background: #fff;
        border: 2px solid rgba(0, 0, 0, 0.12);
        /*box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.12);*/
    }

    .default-color {
        height: 1.93rem;
        width: 4.24rem;
        background-color: #efefef;
        border: 2px solid black;
    }
</style>
