<template>
    <div class="w-100 my-5">
        <div class="main-container">
            <div class="bg-white rounded-4 border mx-2 widget-list overflow-auto" style="height: 630px">
                <h1 class="px-4 py-3 heading">Selected Modules widgets</h1>
                <draggable
                    class="p-4 widgets-container"
                    :list="widgetsList"
                    :group="{ name: 'myGroup', pull: true, put: false }"
                    @start="onDragStart"
                    @end="onDragEnd"
                >
                    <div
                        v-for="(element, index) in widgetsList"
                        :key="index"
                        class="col-4 d-flex justify-content-center"
                    >
                        <div
                            v-if="
                                element.function_type === 'generic' &&
                                !['Mood Tracker', 'Contact', 'Education', 'Track Milestone'].includes(
                                    element.function_name
                                )
                            "
                            class="widget"
                        >
                            <img :src="element.image" alt="" class="m-auto" />
                        </div>
                        <div
                            v-else
                            class="widget widget-title d-flex justify-content-center align-items-center flex-column gap-2"
                        >
                            <img
                                :src="element.image"
                                alt=""
                                :style="{
                                    'max-height': ['Mood Tracker', 'Contact', 'Education', 'Track Milestone'].includes(
                                        element.function_name
                                    )
                                        ? '4rem'
                                        : '5rem',
                                }"
                            />
                            <p class="fw-semibold">
                                {{ element.function_name }}
                            </p>
                        </div>
                    </div>
                </draggable>
            </div>
            <div class="bg-white rounded-4 border mx-2 dash-builder w-50 over" style="height: 630px; overflow-y: auto">
                <div class="d-flex justify-content-between align-items-center">
                    <h1 class="px-4 py-3 heading w-100">Dashboard Builder</h1>
                </div>
                <div class="droppable-template" v-if="template">
                    <div class="primeContainer" :style="{ background: appColors.background_color }">
                        <div
                            class="dashboard-top-bar"
                            :style="{
                                background: appColors.canvas_color,
                                color: appColors.canvas_text_color,
                            }"
                        >
                            <div class="inner-dashboard-top-bar">
                                <div class="d-flex w-10 justify-content-start">
                                    <div class="d-flex justify-content-start ms-3">
                                        <img
                                            src="@/assets/px/drag-and-drop/menu-icon.svg"
                                            alt=""
                                            style="
                                                width: 30px;
                                                height: 30px;
                                                filter: invert(1) grayscale(100%) brightness(200%);
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="w-60 d-flex fw-semibold fs-4">Dashboard</div>
                            </div>
                            <div class="dashboard-header">
                                Hello, Adam Liviston
                                <img
                                    src="@/assets/px/header-icons-images/hello.svg"
                                    alt=""
                                    style="width: 40px; height: 40px"
                                />
                            </div>
                        </div>
                        <div class="t-m-c templateMainContainer" style="margin-top: 5rem">
                            <div
                                class="module-heading flex space-between"
                                :style="{ color: appColors.background_text_color }"
                            >
                                <div
                                    v-if="!isUpperSectionTitleEditing"
                                    class="d-flex align-items-center gap-2 section-title-container"
                                >
                                    <span id="upper-section-title" class="section-title">{{ upperSectionTitle }}</span>
                                    <b-tooltip target="#upper-section-title" triggers="hover" placement="bottom">{{
                                        upperSectionTitle
                                    }}</b-tooltip>
                                    <span
                                        class="flex align-items-center justify-content-center cursor-pointer icon-container"
                                        :class="isLowerSectionTitleEditing ? 'cursor-not-allowed' : ''"
                                        :style="{ pointerEvents: isLowerSectionTitleEditing ? 'none' : 'auto' }"
                                        @click="startEditing('upper')"
                                    >
                                        <span class="material-symbols-outlined" style="color: #1773b1"> edit </span>
                                    </span>
                                </div>
                                <div v-else class="flex gap-2 align-items-center section-title-container">
                                    <input v-model="editedTitle" @keyup.enter="saveSectionTitle('upper')" />
                                    <span
                                        class="flex align-items-center justify-content-center cursor-pointer icon-container"
                                        @click="saveSectionTitle('upper')"
                                    >
                                        <span class="material-symbols-outlined" style="color: #1773b1"> check </span>
                                    </span>
                                    <span
                                        class="flex align-items-center justify-content-center cursor-pointer icon-container"
                                        style="border: 2px solid #a61d21"
                                        @click="isUpperSectionTitleEditing = false"
                                    >
                                        <span class="material-symbols-outlined" style="color: #a61d21"> close </span>
                                    </span>
                                </div>
                                <span
                                    class="upload-btn px-3 py-2 d-flex gap-2 align-items-center cursor-pointer"
                                    @click="addItemsInTemplate('LI')"
                                >
                                    <img src="@/assets/px/add.png" alt="" style="max-height: 25px" />
                                    Add Button</span
                                >
                            </div>
                            <div class="u-l-c upperLayoutContainer">
                                <div
                                    v-for="(item, index) in paginatedItems('LI')"
                                    :key="item[1].name"
                                    :class="
                                        'upperLayoutContainerItemCommon ' +
                                        item[1].class +
                                        (width && height && !item[1].elements.length ? ' highlighted-section ' : '')
                                    "
                                    :style="item[1].style"
                                >
                                    <draggable
                                        class="draggable-list"
                                        :list="item[1].elements"
                                        :group="
                                            item[1].elements.length
                                                ? { name: 'myGroup', pull: true, put: false }
                                                : { name: 'myGroup', pull: true, put: true }
                                        "
                                        @start="onMoveStart"
                                        @end="onMoveEnd"
                                        @change="manipulateWidgets($event, 'LI', item[1].name, 'add', item, index)"
                                        :options="{ accept: false }"
                                    >
                                        <div
                                            v-for="value in item[1].elements"
                                            :class="value.class"
                                            class="module"
                                            :key="value.name"
                                            @mouseover="hoveredElementId = 'LI' + index"
                                            @mouseleave="hoveredElementId = null"
                                            @click="addDetails(value.function_name, index, 'LI')"
                                        >
                                            <div
                                                v-if="value.function_type === 'generic'"
                                                class="widget-img"
                                                :style="{
                                                    height: item[1].title.length === 0 ? '100%' : '7rem',
                                                }"
                                            >
                                                <img :src="value.image" alt="" />
                                            </div>
                                            <div v-else class="widget-img" style="height: 7rem">
                                                <img :src="value.image" alt="" />
                                            </div>
                                            <div class="widget-title">
                                                <p v-if="item[1].title">{{ item[1].title }}</p>
                                            </div>
                                            <template v-if="hoveredElementId == 'LI' + index">
                                                <div class="w-100 h-100 action-buttons">
                                                    <span
                                                        class="background"
                                                        @click="
                                                            manipulateWidgets(
                                                                $event,
                                                                'LI',
                                                                item[1].name,
                                                                'remove',
                                                                value,
                                                                index
                                                            )
                                                        "
                                                        id="delete"
                                                    >
                                                        <img
                                                            src="@/assets/px/delete-icon.png"
                                                            alt=""
                                                            style="height: 18px"
                                                        />
                                                    </span>
                                                    <b-tooltip target="delete" triggers="hover" placement="bottom"
                                                        >Delete</b-tooltip
                                                    >
                                                    <span
                                                        class="background"
                                                        @click="OpenEditModal(value, index, 'LI')"
                                                        id="edit"
                                                    >
                                                        <img
                                                            src="@/assets/px/edit-icon.png"
                                                            alt=""
                                                            style="height: 18px"
                                                        />
                                                    </span>
                                                    <b-tooltip target="edit" triggers="hover" placement="bottom"
                                                        >Edit</b-tooltip
                                                    >
                                                </div>
                                            </template>
                                        </div>
                                    </draggable>
                                </div>
                            </div>
                            <!-- Pagination Indicators -->
                            <div v-if="totalPages('LI') > 1" class="mt-4 flex align-items-center justify-center gap-2">
                                <div
                                    v-for="page in totalPages('LI')"
                                    :key="page"
                                    class="pagination cursor-pointer"
                                    :style="{
                                        background:
                                            page === currentLIPage
                                                ? appColors.canvas_color
                                                : appColors.canvas_text_color,
                                    }"
                                    @click="handlePageChange('LI', page)"
                                />
                            </div>
                        </div>
                        <div class="t-m-c templateMainContainer" style="padding: 0 2rem 2rem">
                            <div
                                class="module-heading flex space-between"
                                :style="{ color: appColors.background_text_color }"
                            >
                                <div
                                    v-if="!isLowerSectionTitleEditing"
                                    class="d-flex align-items-center gap-2 section-title-container"
                                >
                                    <span id="lower-section-title" class="section-title">{{ lowerSectionTitle }}</span>
                                    <b-tooltip target="#lower-section-title" triggers="hover" placement="bottom">{{
                                        lowerSectionTitle
                                    }}</b-tooltip>
                                    <span
                                        class="flex align-items-center justify-content-center cursor-pointer icon-container"
                                        :class="isUpperSectionTitleEditing ? 'cursor-not-allowed' : ''"
                                        :style="{ pointerEvents: isUpperSectionTitleEditing ? 'none' : 'auto' }"
                                        @click="startEditing('lower')"
                                    >
                                        <span class="material-symbols-outlined" style="color: #1773b1"> edit </span>
                                    </span>
                                </div>
                                <div v-else class="flex gap-2 align-items-center section-title-container">
                                    <input v-model="editedTitle" @keyup.enter="saveSectionTitle('lower')" />
                                    <span
                                        class="flex align-items-center justify-content-center cursor-pointer icon-container"
                                        @click="saveSectionTitle('lower')"
                                    >
                                        <span class="material-symbols-outlined" style="color: #1773b1"> check </span>
                                    </span>
                                    <span
                                        class="flex align-items-center justify-content-center cursor-pointer icon-container"
                                        style="border: 2px solid #a61d21"
                                        @click="isLowerSectionTitleEditing = false"
                                    >
                                        <span class="material-symbols-outlined" style="color: #a61d21"> close </span>
                                    </span>
                                </div>
                                <span
                                    class="upload-btn px-3 py-2 d-flex gap-2 align-items-center cursor-pointer"
                                    @click="addItemsInTemplate('GSI')"
                                >
                                    <img src="@/assets/px/add.png" alt="" style="max-height: 25px" />
                                    Add Button</span
                                >
                            </div>
                            <div class="u-l-c upperLayoutContainer grid-container">
                                <div
                                    v-for="(item, index) in paginatedItems('GSI')"
                                    :class="
                                        'upperLayoutContainerItemCommon ' +
                                        item[1].class +
                                        (width && height && !item[1].elements.length ? ' highlighted-section ' : '')
                                    "
                                    :style="item[1].style"
                                    :key="item[1].name + ''"
                                >
                                    <draggable
                                        class="draggable-list"
                                        :list="template['GSI'][item[0]].elements"
                                        :group="
                                            template['GSI'][item[0]].elements.length
                                                ? { name: 'myGroup', pull: true, put: false }
                                                : { name: 'myGroup', pull: true, put: true }
                                        "
                                        @start="onMoveStart"
                                        @end="onMoveEnd"
                                        @change="manipulateWidgets($event, 'GSI', item[1].name, 'add', item, index)"
                                        :options="{ accept: false }"
                                    >
                                        <div
                                            v-for="value in template['GSI'][item[0]].elements"
                                            :key="value.name"
                                            :class="value.class"
                                            class="module"
                                            @mouseover="hoveredElementId = 'GSI' + index"
                                            @mouseleave="hoveredElementId = null"
                                            @click="addDetails(value.function_name, index, 'GSI')"
                                        >
                                            <div
                                                v-if="value.function_type == 'generic'"
                                                class="widget-img"
                                                :style="{
                                                    height:
                                                        template['GSI'][item[0]].title.length == 0 ? '100%' : '7rem',
                                                }"
                                            >
                                                <img :src="value.image" alt="" />
                                            </div>
                                            <div v-else class="widget-img" style="height: 7rem">
                                                <img :src="value.image" alt="" />
                                            </div>
                                            <div class="widget-title">
                                                <p v-if="template['GSI'][item[0]].title">
                                                    {{ template['GSI'][item[0]].title }}
                                                </p>
                                            </div>
                                            <template v-if="hoveredElementId == 'GSI' + index">
                                                <div class="w-100 h-100 action-buttons">
                                                    <span
                                                        class="background"
                                                        @click="
                                                            manipulateWidgets(
                                                                $event,
                                                                'GSI',
                                                                item[1].name,
                                                                'remove',
                                                                value,
                                                                index
                                                            )
                                                        "
                                                        id="delete"
                                                    >
                                                        <img
                                                            src="@/assets/px/delete-icon.png"
                                                            alt=""
                                                            style="height: 18px"
                                                        />
                                                    </span>
                                                    <b-tooltip target="delete" triggers="hover" placement="bottom"
                                                        >Delete</b-tooltip
                                                    >
                                                    <span
                                                        class="background"
                                                        @click="OpenEditModal(value, index, 'GSI')"
                                                        id="edit"
                                                    >
                                                        <img
                                                            src="@/assets/px/edit-icon.png"
                                                            alt=""
                                                            style="height: 18px"
                                                        />
                                                    </span>
                                                    <b-tooltip target="edit" triggers="hover" placement="bottom"
                                                        >Edit</b-tooltip
                                                    >
                                                </div>
                                            </template>
                                        </div>
                                    </draggable>
                                </div>
                            </div>
                            <!-- Pagination Indicators -->
                            <div v-if="totalPages('GSI') > 1" class="mt-4 flex align-items-center justify-center gap-2">
                                <div
                                    v-for="page in totalPages('GSI')"
                                    :key="page"
                                    class="pagination cursor-pointer"
                                    :style="{
                                        background:
                                            page === currentGSIPage
                                                ? appColors.canvas_color
                                                : appColors.canvas_text_color,
                                    }"
                                    @click="handlePageChange('GSI', page)"
                                />
                            </div>
                        </div>

                        <!-- RSS Section -->
                        <div v-if="rssActive" class="rss-section">
                            <div
                                class="rss-container"
                                :class="width === '250' && height === '250' ? 'highlighted-section' : ''"
                            >
                                <draggable
                                    class="draggable-list"
                                    :list="rssItems"
                                    :group="{ name: 'myGroup', pull: false, put: true }"
                                    @start="onMoveStart"
                                    @end="onMoveEnd"
                                    @change="handleChange"
                                    style="min-height: 14rem"
                                    :options="{ accept: false }"
                                >
                                    <div
                                        v-if="rssSelected"
                                        style="position: relative; text-align: -webkit-center; height: 100%"
                                        @mouseover="hoveredElementId = 'RSS'"
                                        @mouseleave="hoveredElementId = null"
                                        @click="openRssModal = true"
                                    >
                                        <img src="@/assets/photos/RSS Feed.png" alt="" style="border-radius: 2.5rem" />
                                        <template v-if="hoveredElementId === 'RSS'">
                                            <div class="w-100 h-100 action-buttons">
                                                <span class="background" id="delete" @click.stop="handleDelete">
                                                    <img
                                                        src="@/assets/px/delete-icon.png"
                                                        alt=""
                                                        style="height: 15px"
                                                    />
                                                </span>
                                                <b-tooltip target="delete" triggers="hover" placement="bottom">
                                                    Delete
                                                </b-tooltip>
                                                <span class="background" id="edit" @click.stop="openRssModal = true">
                                                    <img src="@/assets/px/edit-icon.png" alt="" style="height: 15px" />
                                                </span>
                                                <b-tooltip target="edit" triggers="hover" placement="bottom">
                                                    Edit
                                                </b-tooltip>
                                            </div>
                                        </template>
                                    </div>
                                </draggable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="addmoduledetail">
            <MilestoneEditModal
                v-if="openEditModal && clickedProfileFunction === 'Track Milestone'"
                :open="openEditModal"
                @close="openEditModal = false"
                :position="position"
                :pageData="pageData"
                :applicationFrame="applicationFrame"
                :group="group"
            />
            <AddDetailModal
                v-else-if="openEditModal"
                :open="openEditModal"
                @close="openEditModal = false"
                :clickedProfileFunction="clickedProfileFunction"
                :position="position"
                :pageData="pageData"
                :applicationFrame="applicationFrame"
                :group="group"
            />
            <RssFeedEditModal
                v-if="openRssModal"
                @cancel="openRssModal = false"
                :rssFeedsDetails="rssFeedsDetails"
                @captureRssFeed="updateRssFeed"
            />
            <EducationFeedEditModal
                v-if="isEducation"
                :dashboardId="dashboardId"
                :position="position"
                :pageData="pageData"
                :applicationFrame="applicationFrame"
                :clickedProfileFunction="clickedProfileFunction"
                :group="group"
                @cancel="isEducation = false"
            />
            <AddModuleDetailModal
                v-if="openCore3rdPartyEditModal"
                :open="openCore3rdPartyEditModal"
                @close="openCore3rdPartyEditModal = false"
                :group="group"
                :position="position"
                :pageData="pageData"
                :applicationFrame="applicationFrame"
                :dashboardId="dashboardId"
                :moduleId="moduleId"
            />
        </div>
        <Loading v-if="loading" />
    </div>
</template>
<script>
    import draggable from 'vuedraggable';
    import MilestoneEditModal from './milestone_module_edit_modal.vue';
    import AddDetailModal from '../../components/company/member-profiles/AddGenericFunctionDetail.vue';
    import AddModuleDetailModal from '../../components/company/member-profiles/AddModuleDetail.vue';
    import EducationFeedEditModal from './education_feed_edit_modal.vue';
    import RssFeedEditModal from './rss_feed_edit_modal.vue';
    import Loading from '@/components/general/loading/loading.vue';
    import { coreFunctions, dashboardBuilder, memberProfile, trackMilestones } from '@/util/apiRequests';
    import { deleteAllEducationResourcesByDashboardId } from '@/util/dashboard';

    export default {
        name: 'Build_Health_Home_Dashboard',
        components: {
            draggable,
            MilestoneEditModal,
            AddDetailModal,
            EducationFeedEditModal,
            AddModuleDetailModal,
            RssFeedEditModal,
            Loading,
        },
        props: {
            pageData: {
                type: Object,
            },
            current_template: {
                type: Number,
            },
            applicationFrame: {
                type: String,
            },
            dashboardId: {
                type: Number,
            },
            rssFeedsDetails: {
                type: Object,
            },
        },
        data() {
            return {
                selectedOption: this.current_template,
                template: null,
                widgetsList: [],
                openEditModal: false,
                templateData: {},
                isEducation: false,
                height: '',
                width: '',
                hoveredElementId: null,
                clickedProfileFunction: '',
                position: '',
                elementDragStartId: '',
                loading: true,
                isItemMoved: false,
                movedWidget: null,
                movedWidgetIndex: null,
                group: null,
                openCore3rdPartyEditModal: false,
                moduleId: null,
                currentLIPage: 1,
                currentGSIPage: 1,
                appColors: {},
                isUpperSectionTitleEditing: false,
                isLowerSectionTitleEditing: false,
                editedTitle: '',
                upperSectionTitle: 'Offered Services',
                lowerSectionTitle: 'Informational Links',
                openRssModal: false,
                rssActive: false,
                rssSelected: false,
                rssItems: [],
            };
        },
        methods: {
            addDetails(val, index, group) {
                const itemsPerPage = group === 'LI' ? 3 : 6;
                const currentPage = group === 'LI' ? this.currentLIPage : this.currentGSIPage;
                const startIndex = (currentPage - 1) * itemsPerPage;
                const i = startIndex + index + 1;
                this.position = i + '';
                this.group = group;
                this.clickedProfileFunction = val;
            },
            OpenEditModal(val, index, group) {
                const itemsPerPage = group === 'LI' ? 3 : 6;
                const currentPage = group === 'LI' ? this.currentLIPage : this.currentGSIPage;
                const startIndex = (currentPage - 1) * itemsPerPage;
                const i = startIndex + index + 1;
                this.position = i + '';
                this.group = group;
                if (val.function_type !== 'generic') {
                    this.openCore3rdPartyEditModal = true;
                    this.moduleId = val.id;
                } else if (val.function_name === 'Education') {
                    this.isEducation = true;
                    this.clickedProfileFunction = val.function_name;
                } else {
                    this.openEditModal = true;
                    this.clickedProfileFunction = val.function_name;
                }
            },
            async geturl(fileId) {
                try {
                    const endpoint = dashboardBuilder.getUrls();
                    const requestData = {
                        fileIds: [fileId],
                    };
                    let response = await this.$api.post(endpoint, requestData);
                    return response?.data?.data[0].image;
                } catch (err) {
                    return null;
                }
            },
            async deleteApplication(element, section, index) {
                try {
                    let i = index + 1;
                    this.position = i + '';
                    this.clickedProfileFunction = element.function_name;

                    const endpoint = memberProfile.getSpecificGenericFunction(
                        this.clickedProfileFunction,
                        this.position,
                        this.dashboardId,
                        this.applicationFrame,
                        section === 'LI' ? 'Upper' : 'Lower'
                    );

                    const functionResponse = await this.$api.get(endpoint);

                    if (!functionResponse) {
                        throw 'e';
                    }

                    const info = functionResponse.data;

                    if (info.info.length) {
                        this.loading = true;
                        const endpoint = memberProfile.deleteGenericModule(
                            info.info[0].id,
                            this.clickedProfileFunction,
                            info.info[0].application,
                            this.pageData?.page2?.isMaster,
                            this.dashboardId
                        );
                        const saveFunctionResponse = await this.$api.delete(endpoint);
                        if (!saveFunctionResponse.data.success) {
                            throw saveFunctionResponse.data.error;
                        }
                        if (this.clickedProfileFunction === 'Track Milestone') {
                            const endpoint1 = trackMilestones.deleteMilestonesByDashboardId(this.dashboardId);

                            const res = await this.$api.delete(endpoint1);

                            if (!res) {
                                throw 'e';
                            }
                        }
                        this.$toasted.success('Application deleted successfully !');
                    } else if (this.clickedProfileFunction === 'Education') {
                        await deleteAllEducationResourcesByDashboardId(this.applicationFrame, this.dashboardId);
                    }
                } catch (err) {
                    const errorMesage = err ? err : 'Failed to delete application !';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.loading = false;
                }
            },
            async getTemplateMetaData() {
                try {
                    let metaData = await this.$api.get(dashboardBuilder.getTemplateMeta(this.selectedOption));
                    let templateMeta = JSON.parse(metaData.data.templateMeta);
                    let _template = templateMeta.templateItems;
                    this.template = _template;

                    Object.keys(this.template).forEach((group) => {
                        let groupElements = Object.keys(this.template[group]);
                        this.templateData[group] = {};
                        groupElements.forEach((groupEl) => {
                            this.templateData[group][groupEl] = undefined;
                        });
                    });

                    const { selectedOption } = this.pageData.page3.colorScheme;

                    Object.keys(this.template).forEach((group) => {
                        Object.keys(this.template[group]).forEach((item) => {
                            this.template[group][
                                item
                            ].style = `background-color:${this.pageData.page3.colorScheme[selectedOption][2]}; color:${this.pageData.page3.colorScheme[selectedOption][3]};`;
                        });
                    });

                    this.$emit('captureValues', {
                        page4: {
                            template: this.template,
                            templateData: this.templateData,
                            upperSectionTitle: this.upperSectionTitle,
                            lowerSectionTitle: this.lowerSectionTitle,
                        },
                    });
                    return true;
                } catch (err) {
                    console.error(err);
                    return false;
                }
            },
            async manipulateWidgets(event, section, listName, operation, element, index) {
                this.loading = true;
                try {
                    const itemsPerPage = section === 'LI' ? 3 : 6;
                    const currentPage = section === 'LI' ? this.currentLIPage : this.currentGSIPage;
                    const startIndex = (currentPage - 1) * itemsPerPage;
                    if (operation === 'remove') {
                        const data = await this.getWidgets();
                        const val = data.find((el) => el.function_name === element.function_name);
                        if (element.function_type !== 'generic') {
                            if (element.function_type == 'core') {
                                let idx = this.widgetsList.findIndex((item) => item.function_type == '3rdParty');
                                if (idx != -1) {
                                    this.widgetsList.splice(idx, 0, val);
                                } else {
                                    this.widgetsList.push(val);
                                }
                            } else {
                                this.widgetsList.push(val);
                            }

                            await this.deleteCoreModule(element.id);
                        } else {
                            if (
                                ['Mood Tracker', 'Education', 'Contact', 'Track Milestone'].includes(
                                    element.function_name
                                )
                            ) {
                                let idx = this.widgetsList.findIndex((item) => item.function_type == 'core');
                                if (idx != -1) {
                                    this.widgetsList.splice(idx, 0, val);
                                } else {
                                    this.widgetsList.push(val);
                                }

                                val.class = 'list-item removed';
                                this.template[section][listName].title = '';
                                this.template[section][listName].elements.pop();
                                this.templateData[section][listName] = undefined;
                                this.$set(this.template[section][listName], 'group', undefined);
                            }

                            await this.deleteApplication(element, section, startIndex + index);
                        }

                        element.class = 'list-item removed';

                        const { selectedOption } = this.pageData.page3.colorScheme;

                        this.template[section][listName].elements.pop();
                        this.template[section][listName].title = '';
                        this.template[section][listName].style = this.template[section][listName].style.replace(
                            /background-color:[^;]*/,
                            `background-color:${this.pageData.page3.colorScheme[selectedOption][2]}`
                        );

                        const styleArray = this.template[section][listName].style.split(';');
                        for (let i = 0; i < styleArray.length; i++) {
                            const propertyValue = styleArray[i].split(':');
                            if (propertyValue[0].trim() === 'color') {
                                styleArray[i] = `color:${this.pageData.page3.colorScheme[selectedOption][3]}`;
                                break;
                            }
                        }

                        // Join the modified style array back into a string
                        this.template[section][listName].style = styleArray.join(';');
                        this.templateData[section][listName] = undefined;
                        this.$set(this.template[section][listName], 'group', undefined);
                    } else {
                        if (event.removed != undefined) {
                            if (isNaN(this.movedWidgetIndex)) {
                                this.template[section][listName].elements.push(event.removed.element);
                                this.templateData[section][listName] = event.removed.element;
                                return;
                            }
                            await this.moveWidget(event, section, listName, startIndex + index + 1);
                            return;
                        }

                        if (this.isItemMoved) {
                            this.movedWidget = listName;
                            this.group = section;
                            this.movedWidgetIndex = startIndex + index + 1;
                        }

                        let newlyAdded = operation === 'add' ? event.added.element : null;

                        if (!newlyAdded.function_type) {
                            delete newlyAdded.company_id;
                            delete newlyAdded.created_at;
                            delete newlyAdded.updated_at;
                        }

                        if (operation === 'add') {
                            this.$set(this.template[section][listName], 'group', {
                                name: 'myGroup',
                                pull: false,
                                put: false,
                            });
                            this.templateData[section][listName] = newlyAdded;

                            if (newlyAdded.function_type === 'generic' && !this.isItemMoved) {
                                const excludedWidgets = [
                                    'Mood Tracker',
                                    'Education',
                                    'Contact',
                                    'RSS Feed',
                                    'Track Milestone',
                                ];
                                if (!excludedWidgets.includes(newlyAdded.function_name)) {
                                    this.widgetsList.splice(this.elementDragStartId, 0, {
                                        ...event.added.element,
                                        id: event.added.element.id + 1,
                                    });
                                }
                            }

                            if (!this.isItemMoved) {
                                const { width, height, function_type, function_name } = newlyAdded;
                                const { width: templateWidth, height: templateHeight } =
                                    this.template[section][listName];

                                if (width != templateWidth && height != templateHeight) {
                                    const data = await this.getWidgets();
                                    const val = data.find((el) => el.function_name === newlyAdded.function_name);

                                    if (val) {
                                        const idx = this.widgetsList.findIndex((item) => item.function_type === 'core');
                                        if (idx !== -1) {
                                            this.widgetsList.splice(idx, 0, val);
                                        } else {
                                            this.widgetsList.push(val);
                                        }

                                        val.class = 'list-item removed';
                                        this.template[section][listName].title = '';
                                        this.template[section][listName].elements.pop();
                                        this.templateData[section][listName] = undefined;
                                        this.$set(this.template[section][listName], 'group', undefined);
                                    }
                                } else if (function_type !== 'generic') {
                                    await this.addCore3rdPartyModule(
                                        newlyAdded,
                                        section,
                                        listName,
                                        startIndex + index + 1
                                    );
                                } else if (
                                    ['Mood Tracker', 'Contact', 'Education', 'Track Milestone'].includes(function_name)
                                ) {
                                    await this.addGenericModule(newlyAdded, section, listName, startIndex + index + 1);
                                }
                            }
                        }

                        this.$emit('captureValues', { page4: { templateData: this.templateData } });
                    }
                } catch (error) {
                    this.$toasted.error('Failed to add/remove widget');
                } finally {
                    this.loading = false;
                }
            },
            onDragStart(event) {
                this.elementDragStartId = event.item.id;
                let element = event.item._underlying_vm_;
                this.height = element.height;
                this.width = element.width;
            },
            onDragEnd() {
                this.height = null;
                this.width = null;
            },
            onMoveStart(event) {
                this.isItemMoved = true;
                this.onDragStart(event);
            },
            onMoveEnd() {
                this.isItemMoved = false;
                this.onDragEnd();
            },
            async moveWidget(event, section, listName, removedPosition) {
                try {
                    const isGenericFunction = event?.removed?.element?.function_type === 'generic';
                    const elementId = event?.removed?.element?.id;
                    const functionName = event?.removed?.element?.function_name;
                    const currentSection = this.group === 'LI' ? 'Upper' : 'Lower';

                    // Get endpoint and fetch function data
                    let endpoint = isGenericFunction
                        ? await memberProfile.getSpecificGenericFunction(
                              functionName,
                              removedPosition,
                              this.dashboardId,
                              this.applicationFrame,
                              section === 'LI' ? 'Upper' : 'Lower'
                          )
                        : coreFunctions.getModuleDetails(elementId);

                    const functionResponse = await this.$api.get(endpoint);

                    if (!functionResponse?.data?.info?.length) {
                        return;
                    }

                    // Process and update data
                    let data = functionResponse.data.info[0];

                    if (!isGenericFunction) {
                        const { imageUrl, ...rest } = data;
                        data = rest;

                        if (event?.removed?.element?.function_type !== '3rdParty') {
                            delete data.function_id;
                        }
                    }

                    // Update module with new position and section
                    endpoint = isGenericFunction
                        ? memberProfile.updateGenericModule(data.id)
                        : coreFunctions.updateModule(elementId);

                    const updateData = isGenericFunction
                        ? {
                              application: this.applicationFrame,
                              generic_function: functionName,
                              position: this.movedWidgetIndex,
                              section: currentSection,
                          }
                        : {
                              ...data,
                              position: this.movedWidgetIndex,
                              section: currentSection,
                          };

                    await this.$api.put(endpoint, updateData);

                    // Update template styles
                    const { selectedOption } = this.pageData.page3.colorScheme;
                    const updateTemplateStyles = (template, backgroundColor, textColor) => {
                        template.style = template.style.replace(
                            /background-color:[^;]*/,
                            `background-color:${backgroundColor}`
                        );

                        const styleArray = template.style.split(';');
                        const colorIndex = styleArray.findIndex((style) => style.split(':')[0].trim() === 'color');

                        if (colorIndex !== -1) {
                            styleArray[colorIndex] = `color:${textColor}`;
                            template.style = styleArray.join(';');
                        }
                    };

                    // Update source and destination template styles
                    updateTemplateStyles(
                        this.template[section][listName],
                        this.pageData.page3.colorScheme[selectedOption][2],
                        this.pageData.page3.colorScheme[selectedOption][3]
                    );

                    updateTemplateStyles(this.template[this.group][this.movedWidget], data.color, data.textColor);

                    // Update template properties
                    this.template[this.group][this.movedWidget].title = this.template[section][listName].title;
                    this.template[section][listName].elements.pop();
                    this.template[section][listName].title = '';
                    this.templateData[section][listName] = undefined;
                    this.$set(this.template[section][listName], 'group', undefined);
                } catch (err) {
                    this.$toasted.error('Failed to update Application');
                }
            },
            async getWidgets() {
                const response = await Promise.all([
                    this.getGenericWidgets(),
                    this.getCoreWidgets(),
                    this.get3rdPartyFunctionWidgets(),
                ]);

                return response.flat();
            },
            async getGenericWidgets() {
                try {
                    const genericFunctions = this.pageData?.page2?.genericFunctions?.map((el) => el.function_name);

                    // Return an empty array if no functions are present
                    if (!genericFunctions?.length) {
                        return [];
                    }

                    this.rssActive = genericFunctions.includes('RSS Feed');

                    const requestData = {
                        functionNames: genericFunctions,
                        application: this.applicationFrame,
                    };

                    const endpoint = dashboardBuilder.getWidgets();
                    let response = await this.$api.post(endpoint, requestData);

                    let res = response.data.data.map((el) => {
                        let data = {
                            ...el,
                            function_type: 'generic',
                        };
                        return data;
                    });
                    return res;
                } catch (err) {
                    console.error(err);
                    return [];
                }
            },
            async getCoreWidgets() {
                try {
                    const coreFunctions = this.pageData?.page2?.coreFunctions?.map((el) => el.function_name);

                    // Return an empty array if no functions are present
                    if (!coreFunctions?.length) {
                        return [];
                    }

                    const requestData = {
                        functionNames: coreFunctions,
                        application: this.applicationFrame,
                    };
                    const endpoint = dashboardBuilder.getWidgets();
                    let response = await this.$api.post(endpoint, requestData);
                    let res = response.data.data.map((el) => {
                        let data = {
                            ...el,
                            function_type: 'core',
                        };
                        return data;
                    });
                    return res;
                } catch (err) {
                    console.error(err);
                    return [];
                }
            },
            async get3rdPartyFunctionWidgets() {
                try {
                    const thirdPartyFunctions = this.pageData?.page2?.thirdPartyFunctions?.map((el) => el.id);

                    // Return an empty array if no functions are present
                    if (!thirdPartyFunctions?.length) {
                        return [];
                    }

                    const requestData = {
                        functionIds: thirdPartyFunctions,
                    };
                    const endpoint = memberProfile.getFunctionDetailsByIds();
                    let response = await this.$api.post(endpoint, requestData);
                    let thirdPartyWidgets = response.data.data.map((el) => {
                        let data = {
                            ...el,
                            function_type: '3rdParty',
                            width: '230',
                            height: '230',
                        };
                        return data;
                    });
                    return thirdPartyWidgets;
                } catch (err) {
                    console.error(err);
                    return [];
                }
            },
            async addCore3rdPartyModule(functionDetails, group, listName, position) {
                try {
                    this.loading = true;
                    const { selectedOption } = this.pageData.page3.colorScheme;

                    const data = {
                        title: functionDetails.function_name,
                        image: functionDetails.widget_png ?? functionDetails.fileId,
                        color: this.pageData.page3.colorScheme[selectedOption][2],
                        textColor: this.pageData.page3.colorScheme[selectedOption][3],
                        primaryColor: this.pageData.page3.colorScheme[selectedOption][0],
                        secondaryColor: this.pageData.page3.colorScheme[selectedOption][1],
                        position: position,
                        function_type: functionDetails.function_type,
                        function_name: functionDetails.function_name,
                        dashboard_id: this.dashboardId,
                        function_id: functionDetails.id,
                        section: group === 'LI' ? 'Upper' : 'Lower',
                    };

                    if (functionDetails.function_type !== '3rdParty') {
                        delete data.function_id;
                    }

                    const endpoint = coreFunctions.createModule();
                    const functionResponse = await this.$api.post(endpoint, data);

                    if (!functionResponse) {
                        throw 'e';
                    }

                    if (functionResponse?.data?.success) {
                        this.template[group][listName].elements[0].id = functionResponse?.data?.modules?.insertId;
                        this.template[group][listName].title = data.function_name;
                        this.templateData[group][listName].id = functionResponse?.data?.modules?.insertId;
                    }
                } catch (err) {
                    let errMsg = err ?? 'Failed to Add function Details';
                    this.$toasted.error(errMsg);
                } finally {
                    this.loading = false;
                }
            },
            async addGenericModule(functionDetails, group, listName, position) {
                try {
                    this.loading = true;
                    const { selectedOption } = this.pageData.page3.colorScheme;

                    const data = {
                        title: functionDetails.function_name,
                        url: '',
                        image: functionDetails.widget_png ?? functionDetails.fileId,
                        color: this.pageData.page3.colorScheme[selectedOption][2],
                        textColor: this.pageData.page3.colorScheme[selectedOption][3],
                        primaryColor: this.pageData.page3.colorScheme[selectedOption][0],
                        secondaryColor: this.pageData.page3.colorScheme[selectedOption][1],
                        position: position,
                        assigned_to: functionDetails.function_name,
                        dashboard_assigned: this.dashboardId,
                        issaved: 0,
                        application: this.applicationFrame,
                        isMaster: this.pageData?.page2?.isMaster,
                        section: group === 'LI' ? 'Upper' : 'Lower',
                    };

                    const endpoint = memberProfile.createGenericModule();
                    const saveFunctionResponse = await this.$api.post(endpoint, data);

                    if (!saveFunctionResponse.data.success) {
                        throw saveFunctionResponse.data.error;
                    }

                    if (saveFunctionResponse?.data?.success) {
                        this.templateData[group][listName].id = saveFunctionResponse.data.response[0];
                        this.template[group][listName].elements[0].id = saveFunctionResponse.data.response[0];
                        this.template[group][listName].title = functionDetails.function_name;
                    }
                } catch (err) {
                    let errMsg = err ?? 'Failed to Add function Details';
                    this.$toasted.error(errMsg);
                } finally {
                    this.loading = false;
                }
            },
            async deleteCoreModule(moduleId) {
                try {
                    this.loading = true;
                    const endpoint = coreFunctions.deleteModule(moduleId);
                    const saveFunctionResponse = await this.$api.delete(endpoint);
                    if (!saveFunctionResponse.data.success) {
                        throw saveFunctionResponse.data.error;
                    }
                    this.$toasted.success('Application deleted successfully !');
                } catch (err) {
                    const errorMesage = err ? err : 'Failed to delete module !';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.loading = false;
                }
            },
            addItemsInTemplate(group) {
                // Count existing items in the group and determine the new item's number
                const currentCount = Object.keys(this.template[group]).length;
                const newItemNumber = currentCount + 1;

                // Create the default data with the appropriate numbering

                const itemName = group + ' ' + newItemNumber;

                const defaultData = {
                    name: itemName,
                    class: `item-${newItemNumber} grid-item`,
                    title: '',
                    width: 230,
                    height: 230,
                    elements: [],
                };

                // Add the default data to the group in the template
                this.$set(this.template[group], itemName, defaultData);

                // Update templateData to include the new item
                this.templateData[group][itemName] = undefined;

                // Get the selected color scheme
                const { selectedOption } = this.pageData.page3.colorScheme;

                // Apply styles to all items in the template, including the newly added item
                Object.keys(this.template).forEach((group) => {
                    Object.keys(this.template[group]).forEach((item) => {
                        this.template[group][
                            item
                        ].style = `background-color:${this.pageData.page3.colorScheme[selectedOption][2]}; color:${this.pageData.page3.colorScheme[selectedOption][3]};`;
                    });
                });

                // Emit the updated template data
                this.$emit('captureValues', {
                    page4: { template: this.template },
                });
            },
            handlePageChange(group, page) {
                if (group === 'LI') {
                    this.currentLIPage = page;
                } else {
                    this.currentGSIPage = page;
                }
            },
            totalPages(group) {
                const itemsPerPage = group === 'LI' ? 3 : 6;
                return Math.ceil(Object.keys(this.template[group]).length / itemsPerPage);
            },
            paginatedItems(group) {
                const itemsPerPage = group === 'LI' ? 3 : 6;
                const currentPage = group === 'LI' ? this.currentLIPage : this.currentGSIPage;
                const startIndex = (currentPage - 1) * itemsPerPage;
                const endIndex = startIndex + itemsPerPage;
                return Object.entries(this.template[group]).slice(startIndex, endIndex);
            },
            startEditing(type) {
                if (type === 'upper') {
                    this.editedTitle = this.upperSectionTitle;
                    this.isUpperSectionTitleEditing = true;
                } else {
                    this.editedTitle = this.lowerSectionTitle;
                    this.isLowerSectionTitleEditing = true;
                }
            },
            saveSectionTitle(type) {
                if (this.editedTitle.length === 0) {
                    this.$toasted.error('Section title can not be empty');
                    return;
                }
                if (this.editedTitle.length >= 40) {
                    this.$toasted.error('Section title must be less than 40 characters');
                    return;
                }

                const title = this.editedTitle.trim();
                this.editedTitle = '';

                if (type === 'upper') {
                    this.upperSectionTitle = title;
                    this.isUpperSectionTitleEditing = false;
                } else {
                    this.lowerSectionTitle = title;
                    this.isLowerSectionTitleEditing = false;
                }

                this.$emit('captureValues', {
                    page4: { upperSectionTitle: this.upperSectionTitle, lowerSectionTitle: this.lowerSectionTitle },
                });
            },
            async handleChange(evt) {
                const addedElement = evt.added?.element;

                if (addedElement?.function_name === 'RSS Feed') {
                    this.rssSelected = true;
                    this.widgetsList = this.widgetsList.filter((el) => el.function_name !== 'RSS Feed');
                    return;
                }

                const data = await this.getWidgets();
                const val = data.find((el) => el.function_name === addedElement?.function_name);

                if (!val) return; // Exit if the widget is not found

                const { function_type } = addedElement;

                // Determine the insertion index based on function_type
                const targetIndex = this.widgetsList.findIndex((item) =>
                    function_type === 'core' ? item.function_type === '3rdParty' : item.function_type === 'core'
                );

                // Insert or push the widget
                if (targetIndex !== -1) {
                    this.widgetsList.splice(targetIndex, 0, val);
                } else {
                    this.widgetsList.push(val);
                }
            },
            async handleDelete() {
                this.rssSelected = false;
                this.rssItems = [];

                const data = await this.getWidgets();
                const val = data.find((el) => el.function_name === 'RSS Feed');

                if (val) {
                    const targetIndex = this.widgetsList.findIndex((item) => item.function_type === 'core');
                    if (targetIndex !== -1) {
                        this.widgetsList.splice(targetIndex, 0, val);
                    } else {
                        this.widgetsList.push(val);
                    }
                }

                this.$emit('captureRssFeed', null);
            },
            updateRssFeed(data) {
                this.$emit('captureRssFeed', { ...data });
            },
        },
        async created() {
            window.scrollTo(0, 0);

            this.widgetsList = await this.getWidgets();

            const widgets = this.widgetsList.map((el) => el.function_name);
            const widgetsToRemoveFunctionName = [];

            this.appColors = this.pageData?.page3?.appColors;

            if (
                this.rssActive &&
                this.rssFeedsDetails !== null &&
                typeof this.rssFeedsDetails === 'object' &&
                Object.keys(this.rssFeedsDetails).length !== 0
            ) {
                this.rssSelected = true;
            }

            if (this.pageData?.page4 && this.pageData?.page4?.templateData) {
                const { template, templateData, upperSectionTitle, lowerSectionTitle } = this.pageData.page4;

                if (upperSectionTitle) {
                    this.upperSectionTitle = upperSectionTitle;
                }

                if (lowerSectionTitle) {
                    this.lowerSectionTitle = lowerSectionTitle;
                }

                if (!upperSectionTitle && !lowerSectionTitle) {
                    this.$emit('captureValues', {
                        page4: { upperSectionTitle: this.upperSectionTitle, lowerSectionTitle: this.lowerSectionTitle },
                    });
                }

                if (template) {
                    this.template = template;
                }

                if (templateData) {
                    this.templateData = templateData;
                }

                Object.keys(this.template).forEach((parentEl) => {
                    if (!this.template[parentEl]) {
                        this.templateData[parentEl] = {};
                    }
                    Object.keys(this.template[parentEl]).forEach((childEl) => {
                        if (!this.templateData[parentEl][childEl]) {
                            this.templateData[parentEl][childEl] = {};
                        }
                        if (this.templateData[parentEl][childEl].function_type != 'generic') {
                            if (this.templateData[parentEl][childEl]) {
                                let i = widgets.indexOf(this.templateData[parentEl][childEl].function_name);
                                if (i > -1) {
                        //             this.templateData[parentEl][childEl].image = this.widgetsList[i].image;
                        //             this.$set(this.template[parentEl][childEl], 'elements', [
                        //                 this.templateData[parentEl][childEl],
                        //             ]);
                                    widgetsToRemoveFunctionName.push(
                                        this.templateData[parentEl][childEl].function_name
                                    );
                        //             this.$set(this.template[parentEl][childEl], 'group', {
                        //                 name: 'myGroup',
                        //                 pull: false,
                        //                 put: false,
                        //             });
                                } 
                        // else {
                        //             this.$set(this.template[parentEl][childEl], 'elements', []);
                        //             this.templateData[parentEl][childEl] = undefined;
                        //             this.$set(this.template[parentEl][childEl], 'group', {
                        //                 name: 'myGroup',
                        //                 pull: false,
                        //                 put: true,
                        //             });
                        //         }
                            } 
                        // else {
                        //         this.$set(this.template[parentEl][childEl], 'elements', []);
                        //         this.$set(this.template[parentEl][childEl], 'group', {
                        //             name: 'myGroup',
                        //             pull: false,
                        //             put: true,
                        //         });
                            // }
                        }
                        if (
                            ['Mood Tracker', 'Education', 'Contact', 'Track Milestone'].includes(
                                this.templateData[parentEl][childEl]?.function_name
                            )
                        ) {
                            if (this.templateData[parentEl][childEl]) {
                                widgetsToRemoveFunctionName.push(this.templateData[parentEl][childEl]?.function_name);
                            }
                        }
                    });
                });

                if (this.rssSelected) {
                    this.widgetsList = this.widgetsList.filter((el) => el.function_name !== 'RSS Feed');
                }

                widgetsToRemoveFunctionName.forEach((name) => {
                    const i = this.widgetsList.findIndex((el) => el.function_name == name);
                    this.widgetsList.splice(i, 1);
                });

                Object.keys(this.template).forEach((group) => {
                    Object.keys(this.template[group]).forEach(async (item) => {
                        if (this.template[group][item].elements.length) {
                            let elements = this.template[group][item].elements[0];
                            if (elements.image_id) {
                                elements.image = await this.geturl(elements.image_id);
                            } else if (elements.widget_png) {
                                elements.image = await this.geturl(elements.widget_png);
                            } else if (elements.fileId) {
                                elements.image = await this.geturl(elements.fileId);
                            }
                        }
                    });
                });
            } else {
                await this.getTemplateMetaData();
            }
            this.loading = false;
        },
    };
</script>

<style scoped>
    .primeContainer {
        width: auto;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        overflow: hidden;
        --mini-display-width-numeric: 500;
        --upper-layout-container-height: 72%;
        --bottom-info-container-height: 6%;
    }

    .upperLayoutContainerItemCommon {
        background-color: rgba(255, 255, 255, 0.85);
        position: unset;
        margin: auto;
        height: 10rem !important;
        width: 11rem !important;
        border-radius: 2.5rem !important;
        height: 10rem;
        width: 11rem;
        border-radius: 2.5rem;
    }

    .templateMainContainer {
        padding: 7rem 2rem 2rem 2rem;
        width: auto;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-around;
    }

    .upperLayoutContainer {
        width: 100%;
        display: grid;
        align-content: center;
        position: relative;
        grid-template-columns: repeat(3, 1fr);
        height: var(--upper-layout-container-height);
        gap: calc((5rem * (var(--mini-display-width-numeric) / 1400)));
    }

    .main-container {
        display: flex;
        justify-content: space-between;
    }

    .widgets-container {
        display: flex;
        flex-wrap: wrap;
        row-gap: 35px;
    }

    .action-buttons {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        top: 0;
        background: rgb(89, 90, 194, 0.85);
        border-radius: 2.5rem;
    }

    .background {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e8f0fd;
    }

    .upload-btn {
        font-size: 1.2rem;
        border-radius: 20px;
        color: #fff;
        background: #1773b1;
        box-shadow: 0px 20px 5px 0px rgba(0, 0, 0, 0.03);
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 2fr);
    }

    .pagination {
        width: 15px;
        height: 15px;
        border-radius: 50%;
    }

    .module {
        position: relative;
        text-align: -webkit-center;
        height: 100%;
    }

    .module-heading {
        font-size: 1.8rem;
        z-index: 0;
        font-weight: 600;
        margin-bottom: 1.5rem;
        margin-left: 1rem;
    }

    .dashboard-top-bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 10rem;
        border-radius: 0 0 5rem 5rem;
    }

    .inner-dashboard-top-bar {
        height: 4.3rem;
        width: 100%;
        left: 0px;
        text-align: center;
        display: flex;
        align-items: center;
    }

    .dashboard-header {
        font-weight: 700;
        display: flex;
        justify-content: center;
        font-size: 25px;
        margin-top: 10px;
        align-items: center;
        gap: 10px;
    }

    .widget-img {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .widget-img > img {
        max-height: 80%;
        max-width: 80%;
    }

    .widget-title > p {
        width: 85%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: medium;
        text-align: center;
    }

    .widget-list {
        width: 45%;
        min-width: 18rem;
        border: 1px solid rgb(193, 187, 187);
        display: flex;
        flex-direction: column;
    }

    .widget {
        display: flex;
        width: 11rem;
        height: 10rem;
        background-color: white;
        border-radius: 2.5rem;
        box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.15);
    }

    .heading {
        background: #f1f7ff;
        font-weight: bold;
        border-radius: 14px 14px 0 0;
        margin-bottom: 0;
    }

    .draggable-list {
        height: 100%;
        width: 100%;
    }

    .highlighted-section {
        border: 4px dotted #1fcf25;
    }

    .list-item {
        height: 100%;
        width: 100%;
    }

    .section-title-container input {
        border: 2px solid #1773b1;
        padding: 5px 10px;
        border-radius: 10px;
        font-weight: 600;
        font-size: 1.2rem;
    }

    .section-title {
        max-width: 20rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: default;
    }

    .icon-container {
        border-radius: 50%;
        height: 30px;
        width: 30px;
        background-color: #ffffff;
        border: 2px solid #1773b1;
    }

    .rss-section {
        z-index: 20;
        background-color: rgb(255, 255, 255);
        border-radius: 2.5rem;
        color: rgb(81, 85, 195);
        margin-top: 2rem;
        margin: 1rem;
    }

    .rss-container {
        min-height: 14rem;
        position: unset !important;
        margin: auto;
        border-radius: 2.5rem;
    }
</style>
