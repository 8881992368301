<template>
    <NoButtonModal :open="open" id="the_new_client_dropdown_modal">
        <div class="modal-list-third-party-app">
            <div class="section-header d-flex justify-content-between align-items-center ps-4 pe-2 py-1">
                <div class="d-flex justify-content-between align-items-center">
                    <span v-if="showSelectSvgModal" class="label-app-list-header">Select SVG</span>
                    <span v-else class="label-app-list-header">Edit Module</span>
                </div>
                <div v-if="showSelectSvgModal" class="d-flex justify-content-between align-items-center">
                    <span class="d-flex search-svg-icon mx-4 my-2">
                        <input
                            type="text"
                            placeholder="Find icon here"
                            v-model="searchedSvg"
                            @input="handleSearchedSVG"
                        />
                        <img
                            src="@/assets/px/drag-and-drop/search-icon.svg"
                            alt=""
                            class="img mx-2"
                            style="width: 20px"
                        />
                    </span>
                </div>
            </div>
        </div>
        <!-- second modal -->
        <div class="modal-list-third-party-app">
            <ValidationObserver ref="form">
                <form class="p-0 shadow-none">
                    <template v-if="!showLoader && !showSelectSvgModal">
                        <div class="section-body add-new-app-form mx-0">
                            <div
                                v-if="isModuleConfigurable"
                                class="app-img-upload block field-container d-flex align-items-center mb-2 mx-5 px-3"
                            >
                                <label class="me-3 d-flex justify-content-end add-new-app-label">Icon/Images*</label>

                                <div class="d-flex align-items-center w-100 gap-1">
                                    <button
                                        type="button"
                                        class="w-40 no-bg text-button btn-add-new-app"
                                        @click="chooseFile"
                                    >
                                        Choose file
                                    </button>
                                    <button
                                        type="button"
                                        class="no-bg text-button btn-add-new-app w-40"
                                        @click="chooseFromCompany"
                                    >
                                        Choose icon
                                    </button>
                                    <input
                                        id="fileid"
                                        type="file"
                                        name="upload"
                                        ref="upload"
                                        size="100000"
                                        @change="uploadFile"
                                        hidden
                                        required
                                        accept=".svg, .png, .jpg, .jpeg"
                                    />

                                    <div class="img-container-small d-flex justify-content-center align-items-center">
                                        <img
                                            v-if="!uploadedImg && !showImgLoader && !isSvgPresent"
                                            src="@/assets/px/drag-and-drop/dummyUploadImage.svg"
                                        />

                                        <img
                                            v-if="!showImgLoader && isSvgPresent"
                                            :src="svgToBase64"
                                            alt="image not found"
                                        />
                                        <img
                                            v-if="uploadedImg && !showImgLoader && !isSvgPresent"
                                            :src="uploadedImg"
                                            alt="image not uploaded"
                                        />

                                        <div class="d-flex flex-column mt-3" v-if="showImgLoader">
                                            <div
                                                class="d-flex justify-content-center align-items-center img-loader-main"
                                            >
                                                <b-spinner variant="primary" class="img-loader"></b-spinner>
                                            </div>
                                            <span class="img-loader-text">Uploading...</span>
                                        </div>
                                        <ejs-tooltip
                                            v-if="uploadedImg || svgImg"
                                            class="tooltipcontainer"
                                            content="Delete"
                                            target="#target"
                                            cssClass="customtooltip"
                                            position="top"
                                        >
                                            <span
                                                id="target"
                                                class="material-symbols-outlined img-delete-small"
                                                role="button"
                                                @click="resetFileUpload()"
                                            >
                                                delete
                                            </span>
                                        </ejs-tooltip>
                                    </div>
                                </div>
                            </div>

                            <div v-if="isModuleConfigurable" class="d-flex flex-wrap px-4 my-4 flex-column gap-2">
                                <div class="d-flex color-box w-100">
                                    <div class="d-flex my-2 mx-4 w-100">
                                        <div class="d-flex align-items-center w-40" style="gap: 1%">
                                            <input
                                                type="radio"
                                                name="color_type_kiosk"
                                                id="primary_colors"
                                                value="primary_colors"
                                                style="transform: scale(0.8)"
                                                v-model="functionInfo.selectedSchemeOption"
                                                @change="selectColorScheme($event)"
                                            />
                                            <label for="option2" class="fw-bold">Primary Color Option</label>
                                        </div>
                                        <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                            <div
                                                v-for="(value, index) in colorScheme.primary_colors"
                                                class="d-flex align-items-center px-2 w-25 text-uppercase color-box-item"
                                                :key="index"
                                                :style="{ background: value }"
                                            >
                                                {{ value }}
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div class="d-flex color-box w-100">
                                    <div class="d-flex my-2 mx-4 w-100">
                                        <div class="d-flex align-items-center w-40" style="gap: 1%">
                                            <input
                                                type="radio"
                                                name="color_type_kiosk"
                                                id="secondary_colors"
                                                value="secondary_colors"
                                                style="transform: scale(0.8)"
                                                v-model="functionInfo.selectedSchemeOption"
                                                @change="selectColorScheme($event)"
                                            />
                                            <label for="option2" class="fw-bold">Secondary Color Option</label>
                                        </div>
                                        <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                            <div
                                                v-for="(value, index) in colorScheme.secondary_colors"
                                                class="d-flex align-items-center px-2 w-25 text-uppercase color-box-item"
                                                :key="index"
                                                :style="{ background: value }"
                                            >
                                                {{ value }}
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div v-if="isCustosmizedThemeSelected" class="d-flex color-box w-100">
                                    <div class="d-flex my-2 mx-4 w-100">
                                        <div class="d-flex align-items-center w-40" style="gap: 1%">
                                            <input
                                                type="radio"
                                                name="color_type_kiosk"
                                                id="customized_colors"
                                                value="customized_colors"
                                                style="transform: scale(0.8)"
                                                v-model="functionInfo.selectedSchemeOption"
                                                @change="selectColorScheme($event)"
                                            />
                                            <label for="option2" class="fw-bold">Custom Color Option</label>
                                        </div>
                                        <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                            <div
                                                v-for="(value, index) in colorScheme.customized_colors"
                                                class="d-flex align-items-center px-2 w-25 text-uppercase color-box-item"
                                                :key="index"
                                                :style="{ background: value }"
                                            >
                                                {{ value }}
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div class="d-flex color-box w-100">
                                    <div class="d-flex my-2 mx-4 w-100">
                                        <div class="d-flex align-items-center w-40" style="gap: 1%">
                                            <input
                                                type="radio"
                                                name="color_type_kiosk"
                                                id="module_customized_colors"
                                                value="module_customized_colors"
                                                style="transform: scale(0.8)"
                                                v-model="functionInfo.selectedSchemeOption"
                                                @change="selectColorScheme($event)"
                                            />
                                            <label for="option2" class="fw-bold">Custom Module Color Option</label>
                                        </div>
                                        <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                            <div
                                                v-for="(value, index) in colorScheme.module_customized_colors"
                                                class="d-flex align-items-center px-2 w-25"
                                                :key="index"
                                            >
                                                <input
                                                    v-if="
                                                        functionInfo.selectedSchemeOption == 'module_customized_colors'
                                                    "
                                                    type="color"
                                                    name=""
                                                    id=""
                                                    v-model="colorScheme.module_customized_colors[index]"
                                                    @change="selectCustomColorScheme($event, index)"
                                                    class="w-100 p-0"
                                                />
                                                <div v-else class="default-color"></div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="mx-5 px-3">
                                <Input
                                    v-if="isModuleConfigurable"
                                    label="Title"
                                    labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                    type="text"
                                    class="field-container d-flex align-items-center mb-4"
                                    name="title"
                                    placeholder="Enter Title"
                                    v-model="functionInfo.title"
                                    required
                                />
                                <span class="field-container d-flex align-items-center mt-2 mb-4">
                                    <label
                                        for="category"
                                        class="me-3 d-flex add-new-app-label justify-content-end"
                                        style="width: 28%"
                                        >Category</label
                                    >
                                    <Multiselect
                                        id="category"
                                        track-by="id"
                                        label="title"
                                        class="w-70"
                                        placeholder="Search or Select Category"
                                        v-model="categoryInfo"
                                        :options="categoryList"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="functionInfo.module_category = $event.id"
                                    />
                                </span>
                            </div>
                        </div>

                        <div class="section-footer add-new-app-form my-4">
                            <div class="d-flex justify-content-center">
                                <button
                                    type="button"
                                    class="primary btn-save-application"
                                    :disabled="showImgLoader"
                                    @click="updateModule"
                                >
                                    Save
                                </button>
                                <button type="button" class="no-bg text-button btn-cancel px-4" @click="$emit('close')">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </template>
                </form>
            </ValidationObserver>
        </div>

        <!-- Select SVG Modal -->
        <div class="modal-list-third-party-app">
            <ValidationObserver ref="svgForm">
                <form class="p-0 shadow-none">
                    <template v-if="!showLoader && showSelectSvgModal">
                        <div
                            v-if="svgList.length"
                            class="d-flex flex-wrap gap-3 m-3 p-3 overflow-auto svg-img-container"
                        >
                            <div
                                v-for="(svg, index) in svgList"
                                :key="index"
                                :class="(selectedSvg.id === svg.id ? 'active-bg ' : '') + 'img-container'"
                                @click="selectedSvg = svg"
                            >
                                <ejs-tooltip
                                    target="#target"
                                    :content="svg.title"
                                    class="tooltipcontainer"
                                    cssClass="customtooltip"
                                >
                                    <img id="target" :src="svg.imageUrl" alt="" class="img" style="width: 50px" />
                                </ejs-tooltip>
                            </div>
                        </div>
                        <div v-else class="w-100">
                            <div
                                class="d-flex justify-content-center align-items-center py-5"
                                style="min-height: 200px"
                            >
                                <h4>{{ searchedSvg !== '' ? 'No SVG available' : 'No SVG Added' }}</h4>
                            </div>
                        </div>
                        <div class="section-footer add-new-app-form my-4">
                            <div class="d-flex justify-content-center">
                                <button type="button" class="primary btn-save-application" @click="saveSvg">
                                    Save
                                </button>
                                <button
                                    type="button"
                                    class="no-bg text-button btn-cancel px-4"
                                    @click="showSelectSvgModal = false"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </template>
                </form>
            </ValidationObserver>
        </div>

        <div v-if="showLoader" class="d-flex flex-column justify-content-center align-items-center loader-main">
            <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
            <span class="ms-3">Loading ...</span>
        </div>
    </NoButtonModal>
</template>

<script>
    import draggable from 'vuedraggable';
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import SpinLoader from '@/components/general/loading/SpinLoader.vue';
    import { ValidationObserver } from 'vee-validate';
    import { dashboardBuilder, memberProfile, svgPicker, coreFunctions } from '@/util/apiRequests';
    import { category } from '../../../util/apiRequests';

    const initialFunctionInfoState = {
        title: '',
        color: '',
        textColor: '',
        primaryColor: null,
        secondaryColor: null,
        module_category: null,
    };

    export default {
        name: 'AddModuleDetailModal',
        components: { NoButtonModal, ValidationObserver, SpinLoader, draggable },
        props: {
            open: {
                type: Boolean,
                required: true,
            },
            position: {
                type: String,
                required: true,
            },
            pageData: {
                type: Object,
            },
            applicationFrame: {
                type: String,
                required: true,
            },
            current_template: {
                type: Number,
            },
            group: {
                type: String,
            },
            moduleId: {
                type: Number,
            },
            dashboardId: {
                type: Number,
            },
        },
        data() {
            return {
                showLoader: true,
                functionInfo: { ...initialFunctionInfoState },
                uploadedImg: '',
                showImgLoader: false,
                colorScheme: {},
                isCustosmizedThemeSelected: false,
                isModuleLogoUpdated: false,
                svgImg: '',
                svgElement: '',
                showSelectSvgModal: false,
                Position: null,
                isSvgPresent: false,
                allSvgList: [],
                svgList: [],
                selectedSvg: {},
                searchedSvg: '',
                categoryInfo: '',
                categoryList: [],
            };
        },
        computed: {
            isModuleConfigurable() {
                return this.applicationFrame === 'mobex-health-home';
            },
            svgToBase64() {
                if (!this.svgImg) {
                    return;
                }

                // Get the outer HTML representation of the SVG element
                const svgHtml = this.svgImg.outerHTML;

                // Encode the SVG HTML string to base64
                const base64Svg = btoa(svgHtml);

                // Return the base64 encoded SVG data
                return 'data:image/svg+xml;base64,' + base64Svg;
            },
        },
        methods: {
            chooseFile() {
                if (this.$refs.upload != undefined) {
                    this.$refs.upload.click();
                }
            },
            isFileAvailable() {
                return this.$refs.upload.value === '' ? false : true;
            },
            resetFileUpload() {
                this.$refs.upload.value = '';
                this.uploadedImg = '';
                this.functionInfo.image = '';
                this.svgImg = '';
                this.isSvgPresent = false;
            },
            async geturl(fileId) {
                const endpoint = dashboardBuilder.getUrls();
                const requestData = {
                    fileIds: [fileId],
                };
                let response = await this.$api.post(endpoint, requestData);
                return response?.data?.data[0].image;
            },
            async uploadFile(event) {
                if (event.target.files.length > 0) {
                    const file = event.target.files[0];
                    const fileName = file.name;
                    const fileSize = file.size;
                    let fileNameSplit = fileName.split('.');
                    let fileExtension = fileNameSplit[1];
                    if (!['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG', 'svg'].includes(fileExtension)) {
                        this.$toasted.error('Only svg, png, jpg/jpeg files are allowed');
                        this.$refs.upload.value = '';
                        return;
                    }

                    // Check File size should be less than 10 MB
                    if (fileSize / 1024 / 1024 > 10) {
                        this.$toasted.error('Image size should be less than 10MB');
                        this.$refs.upload.value = '';
                        return;
                    }

                    //HTTP trigger for File upload starts
                    try {
                        this.showImgLoader = true;
                        const readerResult = await new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onload = (event) => resolve(event.target.result);
                            reader.onerror = (error) => reject(error);
                            reader.readAsDataURL(file);
                        });

                        this.uploadedImg = readerResult;

                        if (fileExtension === 'svg') {
                            this.svgImg = this.base64ToSvg(this.uploadedImg);
                            this.svgElement = this.svgImg;
                            this.renderSvg(this.svgImg, false);
                            this.isSvgPresent = true;
                        } else {
                            const formData = new FormData();
                            formData.append('file', file);
                            const endpoint = memberProfile.uploadProfileFunctionsImage();
                            const uploadImgResponse = await this.$api.post(endpoint, formData);
                            if (!uploadImgResponse.data.success) {
                                throw uploadImgResponse.data.error;
                            }
                            this.functionInfo.image = uploadImgResponse.data.s3_link;
                            this.isSvgPresent = false;
                        }
                        this.isModuleLogoUpdated = true;
                        this.$toasted.success('Module image uploaded successfully!');
                    } catch (e) {
                        const errorMessage = e ? e : 'Image upload failed !';
                        this.$toasted.error(errorMessage);
                        this.$refs.upload.value = '';
                    } finally {
                        this.showImgLoader = false;
                    }
                }
            },
            selectColorScheme(event) {
                const defaultColors = ['#FFFFFF', '#FF6600', '#FFFFFF', '#000000'];
                const selectedOption = event.target.value;

                // Map of color options
                const colorOptions = {
                    primary_colors: this.colorScheme.primary_colors,
                    secondary_colors: this.colorScheme.secondary_colors,
                    customized_colors: this.colorScheme.customized_colors,
                    module_customized_colors: this.colorScheme.module_customized_colors,
                };

                // Get selected colors or fallback to primary_colors
                const colors = colorOptions[selectedOption] || this.colorScheme.primary_colors;

                // Update colorScheme
                this.colorScheme = {
                    ...this.colorScheme,
                    selectedOption,
                    ...(selectedOption !== 'module_customized_colors' && { module_customized_colors: defaultColors }),
                };

                // Update functionInfo colors
                [
                    this.functionInfo.primaryColor,
                    this.functionInfo.secondaryColor,
                    this.functionInfo.color,
                    this.functionInfo.textColor,
                ] = colors;

                // Handle SVG rendering
                if (this.isSvgPresent) {
                    this.renderSvg(this.svgElement, true);
                    this.isModuleLogoUpdated = true;
                }
            },
            selectCustomColorScheme(event, colorNo) {
                const { selectedOption } = this.colorScheme;
                const colors = [...this.colorScheme[selectedOption]]; // Clone the array to avoid mutating state directly

                // Update the selected color
                colors[colorNo] = event.target.value;
                this.colorScheme[selectedOption] = colors;

                // Update functionInfo colors
                [
                    this.functionInfo.primaryColor,
                    this.functionInfo.secondaryColor,
                    this.functionInfo.color,
                    this.functionInfo.textColor,
                ] = colors;

                // Render SVG if needed
                if ((colorNo === 0 || colorNo === 1) && this.isSvgPresent) {
                    this.isModuleLogoUpdated = true;
                    this.renderSvg(this.svgElement, true);
                }
            },
            async urlToSvg(url, fetchedImg = true) {
                const reader = new FileReader();
                let img = await fetch(url);
                let imgBlob = await img.blob();
                reader.readAsDataURL(imgBlob);

                reader.onload = () => {
                    const base64Data = reader.result.replace(/^data:image\/svg\+xml;base64,/, '');
                    this.svgImg = atob(base64Data);
                    this.svgElement = this.svgImg;
                    this.renderSvg(this.svgImg, false, fetchedImg);
                    this.isSvgPresent = true;
                };
            },
            base64ToSvg(base64String) {
                const base64Data = base64String.replace(/^data:image\/svg\+xml;base64,/, '');
                const svgXml = atob(base64Data);
                return svgXml;
            },
            renderSvg(svgXml, isColorChanged, fetchImage = false) {
                const primaryColor = this.functionInfo.primaryColor;
                const secondaryColor = this.functionInfo.secondaryColor;

                if (!primaryColor || !secondaryColor) {
                    return;
                }

                // Parse SVG XML string
                const parser = new DOMParser();
                const svgDoc = parser.parseFromString(svgXml, 'image/svg+xml');

                // Get the SVG element
                const svgElement = svgDoc.documentElement;

                if (fetchImage) {
                    this.svgImg = svgElement;
                    return;
                }

                const allPaths = svgElement.querySelectorAll('path');

                allPaths.forEach((path) => {
                    if (path.hasAttribute('fill')) {
                        let color = path.getAttribute('fill').split('#')[1].toLowerCase();
                        let colorTypeAvailable = false;
                        if (isColorChanged) {
                            let colorType = path.getAttribute('id');
                            if (colorType === 'primaryColor') {
                                path.setAttribute('fill', primaryColor);
                                colorTypeAvailable = true;
                            } else if (colorType === 'secondaryColor') {
                                path.setAttribute('fill', secondaryColor);
                                colorTypeAvailable = true;
                            }
                        }
                        if (!colorTypeAvailable) {
                            if (
                                (color[0] === 'a' ||
                                    color[0] === 'b' ||
                                    color[0] === 'c' ||
                                    color[0] === 'd' ||
                                    color[0] === 'e' ||
                                    color[0] === 'f') &&
                                (color[2] === 'a' ||
                                    color[2] === 'b' ||
                                    color[2] === 'c' ||
                                    color[2] === 'd' ||
                                    color[2] === 'e' ||
                                    color[2] === 'f') &&
                                (color[4] === 'a' ||
                                    color[4] === 'b' ||
                                    color[4] === 'c' ||
                                    color[4] === 'd' ||
                                    color[4] === 'e' ||
                                    color[4] === 'f')
                            ) {
                                path.setAttribute('id', 'primaryColor');
                                path.setAttribute('fill', primaryColor);
                            } else {
                                path.setAttribute('id', 'secondaryColor');
                                path.setAttribute('fill', secondaryColor);
                            }
                        }
                    }
                });

                this.svgImg = svgElement;
            },
            async getSvgList() {
                try {
                    const endpoint = svgPicker.getSvgList();
                    const res = await this.$api.get(endpoint);
                    this.allSvgList = res.data;
                    this.svgList = res.data;
                    this.searchedSvg = '';
                } catch (err) {
                    this.$toasted.error('Failed to fetch SVG List');
                }
            },
            chooseFromCompany() {
                this.showSelectSvgModal = true;
                this.searchedSvg = '';
                this.svgList = this.allSvgList;

                const selectedSvgData = this.allSvgList.find((svg) => svg.file_id === this.functionInfo?.image);
                this.selectedSvg = selectedSvgData ?? {};
            },
            handleSearchedSVG() {
                const searchedText = this.searchedSvg?.toLowerCase();
                this.svgList = this.allSvgList.filter(
                    (item) =>
                        item.title.toLowerCase().includes(searchedText) ||
                        item.categories.toLowerCase().includes(searchedText)
                );
            },
            async saveSvg() {
                if (!this.selectedSvg?.file_id) {
                    return this.$toasted.error('Please select any svg image');
                }

                this.showLoader = true;

                const img = await this.geturl(this.selectedSvg?.file_id);
                await this.urlToSvg(img, false);

                this.functionInfo.image = this.selectedSvg.file_id;
                this.showSelectSvgModal = false;
                this.isModuleLogoUpdated = true;
                this.showLoader = false;
            },
            async getCategories() {
                try {
                    const endpoint = category.getCategories(this.$store.state.user.company_id);
                    const response = await this.$api.get(endpoint);
                    this.categoryList = response.data ?? [];
                } catch (err) {
                    this.$toasted.error('Failed to fetch Categories.');
                }
            },
            async fetchModuleDetails() {
                try {
                    if (this.isModuleConfigurable) {
                        const endpoint = coreFunctions.getModuleDetails(this.moduleId);
                        const functionResponse = await this.$api.get(endpoint);

                        if (!functionResponse) {
                            throw 'e';
                        }

                        const { info } = functionResponse.data;

                        if (info.length) {
                            this.functionInfo.title = info[0].title;
                            this.functionInfo.image = info[0].image;
                            this.functionInfo.color = info[0].color;
                            this.functionInfo.textColor = info[0].textColor;
                            this.functionInfo.primaryColor = info[0].primaryColor;
                            this.functionInfo.secondaryColor = info[0].secondaryColor;
                            this.functionInfo.selectedSchemeOption = info[0].selectedSchemeOption;
                            this.functionInfo.module_category = info[0].module_category;
                            this.uploadedImg = info[0].imageUrl;

                            this.categoryInfo = this.categoryList.find(
                                (category) => category.id === info[0].module_category
                            );

                            if (this.functionInfo.selectedSchemeOption == 'module_customized_colors') {
                                this.colorScheme = {
                                    ...this.colorScheme,
                                    selectedOption: 'module_customized_colors',
                                    module_customized_colors: [
                                        info[0].primaryColor,
                                        info[0].secondaryColor,
                                        info[0].color,
                                        info[0].textColor,
                                    ],
                                };
                            }

                            if (this.functionInfo?.image) {
                                let fileExtension = this.functionInfo.image?.split('.')[1];
                                if (['svg', 'svg+xml'].includes(fileExtension)) {
                                    await this.urlToSvg(this.uploadedImg);
                                }
                            }
                        }
                    } else if (this.applicationFrame === 'mobex-health-hub') {
                        const { template, templateData } = this.pageData.page4;
                        Object.keys(template).forEach((group) => {
                            if (group === this.group) {
                                Object.keys(template[group]).forEach((item) => {
                                    const position =
                                        this.current_template === 1 && this.group === 'GSI'
                                            ? JSON.stringify(this.Position - 1) + '1'
                                            : this.Position;
                                    if (item === group + ' ' + position) {
                                        this.categoryInfo = this.categoryList.find(
                                            (category) => category.id === templateData[group][item].module_category
                                        );
                                    }
                                });
                            }
                        });
                    } else {
                        const { template, templateData } = this.pageData.page4;
                        Object.keys(template.LI).forEach((item) => {
                            if (item === 'LI ' + this.Position) {
                                this.categoryInfo = this.categoryList.find(
                                    (category) => category.id === templateData.LI[item].module_category
                                );
                            }
                        });
                    }
                } catch (err) {
                    this.$toasted.error('Failed to fetch module details');
                }
            },
            async updateModule() {
                try {
                    if (this.isModuleConfigurable) {
                        const form = this.$refs.form;
                        const isFormValid = await form.validate();

                        if (!isFormValid) {
                            this.$toasted.error('Please fill all required fields');
                            return;
                        }

                        if (!this.isFileAvailable() && !this.functionInfo.image.length) {
                            this.$toasted.error('Please upload or select Image');
                            return;
                        }

                        this.showLoader = true;

                        if (this.isSvgPresent && this.isModuleLogoUpdated) {
                            let isImageUploaded = await this.uploadModuleLogo();

                            if (!isImageUploaded) {
                                throw 'e';
                            }
                        }

                        this.functionInfo.section = this.group === 'LI' ? 'Upper' : 'Lower';

                        const endpoint = coreFunctions.updateModule(this.moduleId);

                        const saveFunctionResponse = await this.$api.put(endpoint, this.functionInfo);

                        if (!saveFunctionResponse.data.success) {
                            throw saveFunctionResponse.data.error;
                        }

                        const { template } = this.pageData.page4;

                        Object.keys(template).forEach((group) => {
                            if (group === this.group) {
                                Object.keys(template[group]).forEach(async (item) => {
                                    if (item == group + ' ' + this.Position) {
                                        if (this.functionInfo.color != '') {
                                            template[group][item].style = template[group][item].style.replace(
                                                /background-color:[^;]*/,
                                                `background-color:${this.functionInfo.color};`
                                            );
                                        } else {
                                            template[group][item].style = template[group][item].style.replace(
                                                /background-color:[^;]*/,
                                                `background-color:${
                                                    this.pageData.page3.colorScheme[
                                                        this.pageData.page3.colorScheme.selectedOption
                                                    ][0]
                                                };`
                                            );
                                        }

                                        if (this.functionInfo.textColor != '') {
                                            template[group][item].style = template[group][item].style.replace(
                                                /(?:^|;)\s*color\s*:[^;]*/,
                                                `color:${this.functionInfo.textColor}`
                                            );
                                        } else {
                                            template[group][item].style = template[group][item].style.replace(
                                                /(?:^|;)\s*color\s*:[^;]*/,
                                                `color:${
                                                    this.pageData.page3.colorScheme[
                                                        this.pageData.page3.colorScheme.selectedOption
                                                    ][1]
                                                }`
                                            );
                                        }

                                        template[group][item].primaryColor = this.functionInfo.primaryColor;
                                        template[group][item].secondaryColor = this.functionInfo.secondaryColor;

                                        template[group][item].title = this.functionInfo.title;

                                        if (this.functionInfo.image) {
                                            template[group][item].elements[0].image_id = this.functionInfo.image;
                                            let res = await this.geturl(this.functionInfo.image);
                                            template[group][item].elements[0].image = res;
                                        }
                                    }
                                });
                            }
                        });
                    } else if (this.applicationFrame === 'mobex-health-hub') {
                        const { template, templateData } = this.pageData.page4;
                        Object.keys(template).forEach((group) => {
                            if (group === this.group) {
                                Object.keys(template[group]).forEach((item) => {
                                    const position =
                                        this.current_template === 1 && this.group === 'GSI'
                                            ? JSON.stringify(this.Position - 1) + '1'
                                            : this.Position;
                                    if (item === group + ' ' + position) {
                                        templateData[group][item].module_category = this.categoryInfo?.id ?? null;
                                        templateData[group][item].module_title = this.categoryInfo?.title ?? null;
                                    }
                                });
                            }
                        });
                    } else {
                        const { template, templateData } = this.pageData.page4;
                        Object.keys(template.LI).forEach((item) => {
                            if (item === 'LI ' + this.Position) {
                                templateData.LI[item].module_category = this.categoryInfo?.id ?? null;
                                templateData.LI[item].module_title = this.categoryInfo?.title ?? null;
                            }
                        });
                    }

                    this.$toasted.success('Module updated successfully !');
                    this.$emit('close');
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to update module !';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.showLoader = false;
                }
            },
            async uploadModuleLogo() {
                try {
                    let filename = 'module-logo.svg';
                    let svgElement = this.svgImg;

                    const svgData = new XMLSerializer().serializeToString(svgElement);

                    const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });

                    const svgFile = new File([svgBlob], filename, { type: 'image/svg+xml' });

                    const formData = new FormData();
                    formData.append('file', svgFile);

                    const endpoint1 = memberProfile.uploadProfileFunctionsImage();
                    const uploadImgResponse = await this.$api.post(endpoint1, formData);

                    if (!uploadImgResponse.data.success) {
                        throw uploadImgResponse.data.error;
                    }

                    this.functionInfo.image = uploadImgResponse.data.s3_link;

                    return true;
                } catch (err) {
                    this.$toasted.error('Failed to upload Image');
                }
                return false;
            },
        },
        async created() {
            this.Position = this.position;
            if (this.isModuleConfigurable) {
                const { colorScheme } = this.pageData.page3;
                const { selectedOption } = colorScheme;
                this.colorScheme = {
                    ...colorScheme,
                    module_customized_colors: ['#FFFFFF', '#FF6600', '#FFFFFF', '#000000'],
                };
                this.isCustosmizedThemeSelected = colorScheme.selectedOption == 'customized_colors';
                this.functionInfo.selectedSchemeOption = this.colorScheme.selectedOption;
                this.functionInfo.primaryColor = colorScheme[selectedOption][0];
                this.functionInfo.secondaryColor = colorScheme[selectedOption][1];
                this.functionInfo.color = colorScheme[selectedOption][2];
                this.functionInfo.textColor = colorScheme[selectedOption][3];

                await this.getSvgList();
            }

            await this.getCategories();
            await this.fetchModuleDetails();

            this.showLoader = false;
        },
    };
</script>

<style scoped>
    .loader-main {
        min-height: 50vh;
    }

    .add-list-function-loader {
        height: 2em;
        width: 2em;
        font-weight: bolder;
    }

    .img-loader-main {
        min-height: 30px;
    }

    .img-loader {
        height: 0.9em;
        width: 0.9em;
        font-weight: 600;
    }

    .img-loader-text {
        font-size: 0.8em;
        font-weight: 600;
        color: var(--base-theme-color);
    }

    .active-bg {
        width: 50px !important;
        height: 50px !important;
        border: 2px solid lime;
        padding: 2px;
    }

    .img-container {
        padding: 5px;
        border-radius: 10px;
        max-height: 50px;
        cursor: pointer;
        box-shadow: 0px 3px 4px 2px rgba(0, 0, 0, 0.1);
    }

    .search-svg-icon {
        background: #f1f7ff;
        border-radius: 10px;
        border: 2px solid #b1d0ff;
    }

    .search-svg-icon input {
        box-shadow: none;
        background: transparent !important;
    }

    .search-svg-icon input:focus-visible,
    .search-svg-icon input:focus {
        outline: none;
    }

    .search-svg-icon input::placeholder {
        color: #919fad;
        font-size: 14px;
    }

    .color-box {
        width: 45%;
        border-radius: 10px;
        background: #fff;
        border: 2px solid rgba(0, 0, 0, 0.12);
        /*box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.12);*/
    }

    .color-box-item {
        color: #ffffff;
        font-size: 12px;
        border: 2px solid black;
    }

    .default-color {
        height: 1.93rem;
        width: 4.24rem;
        background-color: #efefef;
        border: 2px solid black;
    }

    .svg-img-container {
        max-height: 350px;
        min-height: 200px;
        padding: 5px;
        padding-bottom: 10px;
    }
</style>
